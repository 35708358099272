/* Login.css */
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f9f9f9;
    padding: 20px;
  }
  
  .login-form-container, 
  .register-form-container {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    padding: 30px;
    width: 100%;
    max-width: 450px;
    animation: fadeIn 0.5s ease;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .login-form-container h2,
  .register-form-container h2 {
    color: #c6b291;
    margin-bottom: 25px;
    text-align: center;
    font-size: 1.8rem;
  }
  
  .login-form, 
  .register-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .form-group label {
    font-weight: 500;
    color: #555;
    font-size: 0.95rem;
  }
  
  .form-group input,
  .form-group select {
    padding: 12px 15px;
    border: 1px solid #ddd;
    border-radius: 6px;
    font-size: 1rem;
    transition: border-color 0.3s, box-shadow 0.3s;
  }
  
  .form-group input:focus,
  .form-group select:focus {
    border-color: #c6b291;
    box-shadow: 0 0 0 3px rgba(198, 178, 145, 0.2);
    outline: none;
  }
  
  .error-message {
    color: #d9534f;
    font-size: 0.9rem;
    margin-top: 10px;
    background-color: rgba(217, 83, 79, 0.1);
    padding: 10px;
    border-radius: 4px;
    border-left: 3px solid #d9534f;
  }
  
  .login-btn,
  .register-submit-btn {
    padding: 12px 15px;
    background-color: #c6b291;
    color: white;
    border: none;
    border-radius: 6px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
    margin-top: 10px;
  }
  
  .login-btn:hover,
  .register-submit-btn:hover {
    background-color: #b7a382;
    transform: translateY(-2px);
  }
  
  .login-btn:active,
  .register-submit-btn:active {
    transform: translateY(0);
  }
  
  .register-link,
  .login-link {
    margin-top: 25px;
    text-align: center;
    padding-top: 20px;
    border-top: 1px solid #eee;
  }
  
  .register-link p,
  .login-link p {
    margin-bottom: 10px;
    color: #555;
  }
  
  .register-btn,
  .login-link-btn,
  .cancel-btn {
    background-color: transparent;
    color: #c6b291;
    border: 1px solid #c6b291;
    border-radius: 6px;
    padding: 10px 15px;
    font-size: 0.95rem;
    cursor: pointer;
    transition: all 0.3s;
  }
  
  .register-btn:hover,
  .login-link-btn:hover {
    background-color: rgba(198, 178, 145, 0.1);
  }
  
  .cancel-btn {
    color: #777;
    border-color: #ddd;
    margin-right: 10px;
  }
  
  .cancel-btn:hover {
    background-color: #f5f5f5;
  }
  
  .form-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .role-info {
    margin-top: 10px;
    font-size: 0.9rem;
    color: #777;
    font-style: italic;
  }
  
  .first-use-message {
    text-align: center;
    margin: 20px 0;
    padding: 15px;
    background-color: #f9f9f9;
    border-radius: 6px;
    border: 1px solid #eee;
  }
  
  .first-use-message p {
    margin-bottom: 15px;
    color: #555;
  }
  
  .create-admin-btn {
    background-color: #c6b291;
    color: white;
    border: none;
    border-radius: 6px;
    padding: 10px 15px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100%;
  }
  
  .create-admin-btn:hover {
    background-color: #b7a382;
  }
  
  /* Styles responsifs */
  @media (max-width: 768px) {
    .login-form-container, 
    .register-form-container {
      padding: 20px;
    }
    
    .form-actions {
      flex-direction: column-reverse;
      gap: 10px;
    }
    
    .cancel-btn, 
    .register-submit-btn {
      width: 100%;
      margin-right: 0;
    }
  }

  /* Ajoutez ces styles dans Register.css ou dans les fichiers CSS appropriés */

.form-row {
  display: flex;
  gap: 20px;
  margin-bottom: 10px;
}

.form-row .form-group {
  flex: 1;
}

@media (max-width: 768px) {
  .form-row {
    flex-direction: column;
    gap: 0;
  }
}

/* Assurez-vous que les conseils sur le format de date sont visibles */
.date-format-hint {
  font-size: 0.8rem;
  color: #777;
  margin-top: 5px;
}