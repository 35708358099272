/* Synthese.css */
.synthese-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  width: 100%;
}

.stats-section {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}

.stats-section h2 {
  color: #c6b291;
  font-size: 1.2rem;
  margin-bottom: 15px;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 15px;
}

.stat-card {
  background-color: #f8f8f8;
  border-radius: 8px;
  padding: 15px;
  text-align: center;
}

.stat-card h3 {
  font-size: 0.9rem;
  color: #777;
  margin-bottom: 5px;
}

.stat-value {
  font-size: 1.5rem;
  font-weight: 600;
  color: #c6b291;
}

.stat-subtitle {
  font-size: 0.8rem;
  color: #999;
  margin-top: 5px;
}

.stats-list {
  list-style: none;
  padding: 0;
}

.stats-list-item {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 1px solid #eee;
}

.stats-list-item:last-child {
  border-bottom: none;
}

.stats-list-name {
  font-weight: 600;
}

.stats-list-value {
  color: #777;
}

.revenue-chart {
  overflow-x: auto;
}

.revenue-table {
  width: 100%;
  border-collapse: collapse;
}

.revenue-table th,
.revenue-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #eee;
}

.revenue-table th {
  background-color: #f5f5f5;
  font-weight: 600;
}

.no-data {
  color: #999;
  font-style: italic;
  padding: 10px 0;
}

/* Styles pour la fonctionnalité d'export */
.export-section {
  margin-bottom: 20px;
  position: relative;
}

.export-btn {
  padding: 10px 20px;
  background-color: #c6b291;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-weight: 600;
}

.export-btn:hover {
  background-color: #b7a382;
}

.export-options {
  margin-top: 15px;
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 8px;
  border: 1px solid #eee;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  align-items: flex-end;
}

.export-option-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.export-option-group label {
  font-weight: 600;
  font-size: 0.9rem;
  color: #555;
}

.export-option-group select {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 0.9rem;
  min-width: 200px;
}

.export-confirm-btn {
  padding: 8px 16px;
  background-color: #c6b291;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  height: 37px;
}

.export-confirm-btn:hover {
  background-color: #b7a382;
}

/* Styles pour les sélecteurs de période */
.period-selector-container {
  background-color: #f8f8f8;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.period-type-selector {
  margin-bottom: 15px;
}

.period-type-selector label {
  font-weight: 600;
  color: #555;
  margin-right: 10px;
  display: block;
  margin-bottom: 8px;
}

.period-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.period-btn {
  padding: 8px 15px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s;
}

.period-btn:hover {
  background-color: #f0f0f0;
}

.period-btn.active {
  background-color: #c6b291;
  color: white;
  border-color: #c6b291;
}

.specific-period-selector {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 15px;
}

.period-select {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.period-select label {
  font-size: 0.9rem;
  color: #666;
}

.period-select select {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  min-width: 150px;
}

.view-mode-selector {
  border-top: 1px dashed #ddd;
  padding-top: 15px;
}

.view-mode-selector label {
  font-weight: 600;
  color: #555;
  margin-right: 10px;
  display: block;
  margin-bottom: 8px;
}

.view-mode-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.view-mode-btn {
  padding: 8px 15px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s;
}

.view-mode-btn:hover {
  background-color: #f0f0f0;
}

.view-mode-btn.active {
  background-color: #c6b291;
  color: white;
  border-color: #c6b291;
}

/* Styles pour les onglets des graphiques */
.chart-tabs {
  display: flex;
  gap: 5px;
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
  overflow-x: auto;
  padding-bottom: 2px;
}

.chart-tab {
  padding: 10px 20px;
  cursor: pointer;
  border-bottom: 3px solid transparent;
  transition: all 0.2s;
  white-space: nowrap;
}

.chart-tab:hover {
  background-color: #f8f8f8;
}

.chart-tab.active {
  border-bottom: 3px solid #c6b291;
  color: #c6b291;
  font-weight: 600;
}

/* Styles pour les graphiques */
.charts-container {
  margin-bottom: 30px;
}

.chart-section {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
}

.chart-section h2 {
  color: #c6b291;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 1.4rem;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
}

.chart-container {
  height: 400px;
  margin-bottom: 20px;
}

.chart-summary {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
}

.summary-card {
  background-color: #f8f8f8;
  border-radius: 8px;
  padding: 15px;
  flex: 1;
  min-width: 200px;
  text-align: center;
}

.summary-card h3 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 0.95rem;
  color: #666;
}

.summary-value {
  font-size: 1.8rem;
  font-weight: 600;
  color: #c6b291;
}

/* Styles pour la légende des catégories */
.categories-legend {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 15px;
  margin-top: 20px;
}

.category-legend-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.color-box {
  width: 16px;
  height: 16px;
  border-radius: 4px;
}

.category-name {
  font-weight: 500;
  flex: 1;
}

.category-count {
  color: #666;
}

/* Styles des onglets pour la Synthèse */
.synthese-tabs {
  display: flex;
  gap: 5px;
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
}

.synthese-tab {
  padding: 10px 15px;
  cursor: pointer;
  border-bottom: 3px solid transparent;
  transition: all 0.2s;
}

.synthese-tab.active {
  border-bottom: 3px solid #c6b291;
  color: #c6b291;
  font-weight: 600;
}

/* Contenu des onglets */
.synthese-tab-content {
  display: none;
}

.synthese-tab-content.active {
  display: block;
}

/* Badge admin uniquement */
.admin-only-badge {
  display: inline-block;
  background-color: #dc3545;
  color: white;
  font-size: 0.7rem;
  padding: 3px 8px;
  border-radius: 12px;
  margin-left: 10px;
  vertical-align: middle;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

/* Styles responsifs */
@media (max-width: 768px) {
  .synthese-container {
    grid-template-columns: 1fr;
  }
  
  .export-options {
    flex-direction: column;
    align-items: stretch;
  }
  
  .export-option-group {
    width: 100%;
  }
  
  .export-option-group select {
    width: 100%;
  }
  
  .specific-period-selector,
  .chart-summary {
    flex-direction: column;
  }
  
  .period-select,
  .summary-card {
    width: 100%;
  }
  
  .period-btn,
  .view-mode-btn {
    flex: 1;
    text-align: center;
  }
  
  .categories-legend {
    grid-template-columns: 1fr;
  }
  
  .chart-container {
    height: 300px;
  }
}

/* Ajouter dans Stock.css et Synthese.css */

.sync-status-indicator {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f9fa;
  border-radius: 5px;
  padding: 10px 15px;
  margin-bottom: 20px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  font-size: 14px;
}

.sync-button {
  background-color: #c6b291;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 15px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
}

.sync-button:hover {
  background-color: #b9a583;
}

.sync-button:disabled {
  background-color: #d9d9d9;
  cursor: not-allowed;
}