/* Variables globales */
:root {
  --primary-color: #c6b291;
  --primary-hover: #b7a382;
  --secondary-color: #e0e7f1;
  --accent-color: #eb8989;
  --text-color: #333;
  --light-gray: #f5f5f5;
  --border-color: #ddd;
  --success-color: #5cb85c;
  --warning-color: #f0ad4e;
  --info-color: #5bc0de;
  --danger-color: #d9534f;
}

/* Reset et styles de base */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.agenda-container * {
  box-sizing: border-box;
}

.agenda-container {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: var(--text-color);
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
  padding: 20px;
  overflow: hidden !important;
  padding-bottom: 50px !important;
  min-height: 800px !important;
}

/* Conteneur de calendrier principal */
.calendar-container {
  --hour-height: 60px;
  --visible-hours: calc(var(--working-hours, 10) + 1);
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease, opacity 0.3s ease;
  height: auto !important;
  max-height: none !important;
  min-height: calc(var(--hour-height) * var(--visible-hours) + 70px) !important;
  margin-bottom: 30px !important;
  width: 100% !important;
  border-radius: 8px !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05) !important;
}

/* Contrôles du calendrier */
.agenda-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
  gap: 10px;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 100;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}

.agenda-actions {
  display: flex;
  gap: 15px; 
  align-items: center;
}

/* Sélecteur de vue */
.view-selector {
  display: flex;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
}

.view-btn {
  padding: 8px 16px;
  background-color: #f5f5f5;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  font-weight: 600;
  color: var(--text-color);
  border-right: 1px solid #ddd;
}

.view-btn:first-child {
  border-radius: 6px 0 0 6px;
}

.view-btn:last-child {
  border-radius: 0 6px 6px 0;
  border-right: none;
}

.view-btn.active {
  background-color: var(--primary-color);
  color: white;
  border-color: var(--primary-color);
}

.view-btn:hover:not(.active) {
  background-color: var(--light-gray);
}

/* Navigation de date */
.date-navigation {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: white;
  padding: 5px 10px;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  flex-wrap: wrap;
  justify-content: center;
}

.nav-date-btn {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.3s;
  color: var(--text-color);
}

.nav-date-btn:hover {
  background-color: #e5e5e5;
}

.current-date {
  font-weight: 600;
  font-size: 1.1rem;
  min-width: 250px;
  text-align: center;
  color: var(--primary-color);
}

.today-btn {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-weight: 600;
  position: relative;
  overflow: hidden;
}

.today-btn:hover {
  background-color: #e5e5e5;
}

.today-btn:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5px;
  height: 5px;
  background: rgba(198, 178, 145, 0.3);
  opacity: 0;
  border-radius: 100%;
  transform: scale(1, 1) translate(-50%);
  transform-origin: 50% 50%;
}

.today-btn:focus:not(:active)::after {
  animation: ripple 1s ease-out;
}

/* Bouton nouveau rendez-vous */
.new-btn {
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 600;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.new-btn:hover {
  background-color: var(--primary-hover);
  transform: translateY(-1px);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
}

/* Bouton paramètres */
.settings-btn {
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  gap: 5px;
}

.settings-btn:hover {
  background-color: var(--primary-hover);
}

.settings-icon {
  font-size: 16px;
}

/* Vue de calendrier */
.calendar-view {
  display: none;
  height: 100% !important;
  width: 100% !important;
  overflow: hidden !important;
  position: relative !important;
  border: 1px solid #ddd !important;
  border-radius: 8px !important;
  background-color: white !important;
  min-height: 100% !important;
  margin-top: 10px;
}

.calendar-view.active {
  display: flex !important;
}

.calendar-view::-webkit-scrollbar {
  width: 8px !important;
}

.calendar-view::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
  border-radius: 8px !important;
}

.calendar-view::-webkit-scrollbar-thumb {
  background: #ccc !important;
  border-radius: 8px !important;
}

.calendar-view::-webkit-scrollbar-thumb:hover {
  background: #aaa !important;
}

/* ========== VUE JOUR ========== */
#day-view {
  display: flex !important;
  flex-direction: column !important;
  width: 100% !important;
  height: auto !important;
  overflow: hidden !important;
  min-height: 700px !important;
}

/* On réutilise la structure de la vue semaine pour la vue jour */
#day-view .week-header {
  display: grid !important;
  grid-template-columns: 60px 1fr !important; 
  width: 100% !important;
  height: 60px !important;
  min-height: 60px !important;
  max-height: 60px !important;
  flex-shrink: 0 !important;
  position: sticky !important;
  top: 0 !important;
  z-index: 10 !important;
  background-color: white !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
}

#day-view .week-grid {
  display: grid !important;
  grid-template-columns: 60px 1fr !important;
  width: 100% !important;
  flex: 1 !important;
  overflow-y: auto !important;
  min-height: calc(var(--hour-height) * var(--working-hours, 10)) !important;
  height: auto !important;
  max-height: calc(var(--hour-height) * 15) !important;
  position: relative !important;
  scrollbar-gutter: stable !important;
  align-content: start !important;
  border: 1px solid #ddd !important;
  border-top: none !important;
  background-color: white !important;
}

#day-view.calendar-view {
  min-height: 700px !important;
}

#day-view .week-container {
  display: flex !important;
  flex-direction: column !important;
  height: auto !important;
  width: 100% !important;
  overflow: hidden !important;
  min-height: 650px !important;
}

#day-view .day-column {
  width: 100% !important;
  min-width: 0 !important;
  position: relative !important;
  border-right: 1px solid #ddd !important;
  display: block !important;
  min-height: calc(var(--hour-height) * var(--working-hours, 10)) !important;
  background-color: white !important;
}

.time-column {
  width: 60px !important;
  min-width: 60px !important;
  max-width: 60px !important;
  flex-shrink: 0 !important;
  background-color: #fafafa !important;
  border-right: 1px solid #ddd !important;
  overflow: visible !important;
  display: flex !important;
  flex-direction: column !important;
  z-index: 9 !important;
  position: sticky !important;
  left: 0 !important;
}

.time-header {
  height: 60px !important;
  min-height: 60px !important;
  max-height: 60px !important;
  background-color: #f2f2f2 !important;
  border-right: 1px solid #ddd !important;
  border-bottom: 1px solid #ddd !important;
  box-sizing: border-box !important;
  flex-shrink: 0 !important;
}

.time-slot, .time-cell {
  height: 60px !important;
  min-height: 60px !important;
  max-height: 60px !important;
  border-bottom: 1px solid #ddd !important;
  position: relative !important;
  padding: 5px !important;
  box-sizing: border-box !important;
  flex-shrink: 0 !important;
}

.time-slot {
  text-align: right !important;
  font-size: 0.85rem !important;
  color: #666 !important;
}

.time-slot span, .time-cell span {
  position: absolute !important;
  top: -9px !important;
  right: 10px !important;
  background-color: #fafafa !important;
  padding: 0 5px !important;
  font-weight: 500 !important;
  z-index: 5 !important;
  color: #666 !important;
}

.day-column .time-cell {
  background-image: linear-gradient(to bottom, rgba(0,0,0,0.03) 1px, transparent 1px) !important;
  background-size: 100% 15px !important;
  background-position: 0 0 !important;
}

.day-header {
  height: 60px !important;
  min-height: 60px !important;
  max-height: 60px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-weight: 600 !important;
  background-color: #f2f2f2 !important;
  border-bottom: 1px solid #ddd !important;
  box-sizing: border-box !important;
  margin-bottom: 10px !important;
  text-transform: capitalize;
  color: var(--primary-color);
  font-size: 1.1rem;
}

/* États pour les cellules jour/time */
.time-cell:hover, .time-slot-content:hover, .week-slot:hover {
  background-color: rgba(198, 178, 145, 0.1) !important;
}

.time-cell.selecting, .time-slot-content.selecting, .week-slot.selecting {
  background-color: rgba(198, 178, 145, 0.2) !important;
}

.time-cell.selected, .time-slot-content.selected, .week-slot.selected {
  background-color: rgba(198, 178, 145, 0.3) !important;
}

.time-cell.drag-over {
  background-color: rgba(198, 178, 145, 0.3) !important;
  box-shadow: inset 0 0 0 2px rgba(198, 178, 145, 0.7) !important;
}

/* ========== VUE SEMAINE ========== */
#week-view {
  flex-direction: column !important;
  width: 100% !important;
  height: 100% !important;
  overflow: hidden !important;
}

.week-container {
  display: flex !important;
  flex-direction: column !important;
  height: 100% !important;
  width: 100% !important;
  overflow: hidden !important;
}

.week-header {
  display: grid !important;
  grid-template-columns: 60px repeat(7, 1fr) !important;
  width: 100% !important;
  height: 60px !important;
  min-height: 60px !important;
  max-height: 60px !important;
  flex-shrink: 0 !important;
  position: sticky !important;
  top: 0 !important;
  z-index: 10 !important;
  background-color: white !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
  overflow: visible !important;
  padding-right: 17px !important;
  box-sizing: border-box !important;
}

.week-grid {
  display: grid !important;
  grid-template-columns: 60px repeat(7, 1fr) !important;
  width: 100% !important;
  flex: 1 !important;
  overflow-y: auto !important;
  min-height: 0 !important;
  max-height: calc(100% - 60px) !important;
  position: relative !important;
  scrollbar-gutter: stable !important;
  padding-top: 10px !important;
}

.time-header-cell {
  height: 60px !important;
  width: 60px !important;
  min-width: 60px !important;
  max-width: 60px !important;
  background-color: #f2f2f2 !important;
  border-right: 1px solid #ddd !important;
  border-bottom: 1px solid #ddd !important;
  box-sizing: border-box !important;
  overflow: visible !important;
  flex-shrink: 0 !important;
  z-index: 11 !important;
}

.day-header-cell {
  height: 60px !important;
  min-height: 60px !important;
  max-height: 60px !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  border-right: 1px solid #ddd !important;
  border-bottom: 1px solid #ddd !important;
  background-color: #f2f2f2 !important;
  box-sizing: border-box !important;
  width: 100% !important;
}

.day-header-cell:last-child {
  border-right: none;
}

.day-name {
  font-weight: 600;
  margin-bottom: 3px;
  font-size: 0.9rem;
  text-transform: capitalize;
}

.day-date {
  font-size: 1.2rem;
  font-weight: 700;
}

.day-month {
  font-size: 0.8rem;
  color: #666;
  margin-top: 3px;
}

.day-column {
  display: flex !important;
  flex-direction: column !important;
  position: relative !important;
  border-right: 1px solid #ddd !important;
  min-width: 0 !important;
  box-sizing: border-box !important;
  overflow: visible !important;
  width: 100% !important;
}

.day-column:last-child {
  border-right: none;
}

.week-slot {
  height: 60px;
  border-bottom: 1px solid #ddd;
  position: relative;
  user-select: none;
  cursor: pointer;
  transition: background-color 0.1s;
  background-image: linear-gradient(to bottom, rgba(0,0,0,0.03) 1px, transparent 1px);
  background-size: 100% 15px;
  width: 100%;
  box-sizing: border-box;
  background-color: #ffffff;
}

/* États des colonnes et cellules */
.day-column.today, .week-day.today {
  background-color: rgba(224, 231, 241, 0.2) !important;
}

.day-header-cell.today, .week-day.today .day-header {
  background-color: #e0e7f1 !important;
  font-weight: bold;
}

.day-column.weekend, .week-day.weekend {
  background-color: #fafafa !important;
}

.day-header-cell.weekend, .week-day.weekend .day-header {
  color: #d9534f;
  background-color: #f5f5f5;
}

.day-column.closed-day, .week-day.closed-day {
  background-color: #f8f8f8 !important;
  opacity: 0.8 !important;
  position: relative !important;
}

.day-column.closed-day::before, .week-day.closed-day::before, 
.day-header-cell.closed-day::after, .month-day.closed-day::before {
  content: '' !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  background-image: repeating-linear-gradient(
    45deg,
    transparent,
    transparent 5px,
    rgba(0, 0, 0, 0.02) 5px,
    rgba(0, 0, 0, 0.02) 10px
  ) !important;
  pointer-events: none !important;
  z-index: 1 !important;
}

.day-header-cell.closed-day, .week-day.closed-day .day-header {
  background-color: #f0f0f0 !important;
  color: #aaa !important;
  position: relative !important;
}

.week-day.closed-day .week-slot {
  background-color: #f0f0f0;
  cursor: not-allowed;
}

/* Barres de défilement*/
.week-grid::-webkit-scrollbar,
.day-grid::-webkit-scrollbar,
.day-content::-webkit-scrollbar {
  width: 8px !important;
}

.week-grid::-webkit-scrollbar-track,
.day-grid::-webkit-scrollbar-track,
.day-content::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
  border-radius: 8px !important;
}

.week-grid::-webkit-scrollbar-thumb,
.day-grid::-webkit-scrollbar-thumb,
.day-content::-webkit-scrollbar-thumb {
  background: #ccc !important;
  border-radius: 8px !important;
}

.week-grid::-webkit-scrollbar-thumb:hover,
.day-grid::-webkit-scrollbar-thumb:hover,
.day-content::-webkit-scrollbar-thumb:hover {
  background: #aaa !important;
}

/* ========== VUE MOIS ========== */
#month-view {
  flex-direction: column !important;
  overflow-y: auto !important;
  height: 100% !important;
}

.month-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  border-bottom: 1px solid #ddd;
  height: 100%;
  align-content: start;
  width: 100%;
}

.weekday-header {
  padding: 15px 10px;
  text-align: center;
  font-weight: 600;
  background-color: #f5f5f5;
  border-bottom: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
  font-size: 0.9rem;
}

.weekday-header:last-child {
  border-right: none;
}

.month-day {
  min-height: 100px;
  padding: 5px;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  position: relative;
  overflow: hidden;
  transition: background-color 0.2s;
  height: 100px;
  min-width: 0;
  box-sizing: border-box;
}

.month-day:nth-child(7n) {
  border-right: none;
}

.month-day:hover {
  background-color: rgba(198, 178, 145, 0.05);
}

.month-day.today {
  background-color: #f0f7ff;
  position: relative;
  font-weight: bold;
  box-shadow: inset 0 0 0 2px #c6b291;
}

.month-day.today::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 3px;
  background-color: var(--primary-color);
}

.month-day.next-month,
.month-day.prev-month {
  color: #aaa;
  background-color: #f9f9f9;
}

.month-day.closed-day {
  background-color: #f5f5f5;
  opacity: 0.7;
  position: relative;
  cursor: not-allowed;
}

.month-header {
  background-color: var(--primary-color);
  color: white;
  padding: 10px;
  text-align: center;
}

.month-header h3 {
  margin: 0;
  font-size: 1.2rem;
}

.month-calendar {
  padding: 10px;
}

.month-weekdays {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  text-align: center;
  font-weight: 600;
  font-size: 0.8rem;
  color: #666;
  margin-bottom: 5px;
}

.month-days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 2px;
}

.month-day-mini {
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  font-weight: 500;
  border-radius: 4px;
  transition: background-color 0.2s;
  cursor: pointer;
  position: relative;
}

.month-day-mini:hover {
  background-color: #f5f5f5;
}

.month-day-mini.other-month {
  color: #ccc;
}

.month-day-mini.today {
  background-color: rgba(224, 231, 241, 0.5);
  font-weight: 700;
  box-shadow: inset 0 0 0 2px var(--primary-color);
}

.month-day-mini.closed-day {
  background-color: #f9f9f9;
  color: #aaa;
}

.month-day-mini.appointment-level-1, .appointment-level-1 {
  background-color: rgba(198, 178, 145, 0.2);
}

.month-day-mini.appointment-level-2, .appointment-level-2 {
  background-color: rgba(198, 178, 145, 0.4);
}

.month-day-mini.appointment-level-3, .appointment-level-3 {
  background-color: rgba(198, 178, 145, 0.6);
}

.month-day-mini.appointment-level-4, .appointment-level-4 {
  background-color: rgba(198, 178, 145, 0.8);
  color: white;
}

.month-details {
  padding: 15px;
  background-color: #f9f9f9;
  border-top: 1px solid #eee;
}

.month-details h4 {
  margin: 0 0 15px 0;
  font-size: 1.1rem;
  color: #555;
}

.month-appointments-list {
  max-height: 400px;
  overflow-y: auto;
}

/* ========== RENDEZ-VOUS ========== */
.appointment {
  position: absolute !important;
  left: 2px !important; /* Sera remplacé dynamiquement */
  right: auto !important; /* Désactiver la propriété right pour permettre le dimensionnement par width */
  width: 95% !important; /* Sera remplacé dynamiquement */
  background-color: var(--primary-color) !important;
  color: white !important;
  padding: 5px 8px !important;
  border-radius: 4px !important;
  font-size: 0.85rem !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  cursor: pointer !important;
  transition: transform 0.2s ease, box-shadow 0.2s ease !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1) !important;
  border-left: 3px solid rgba(0, 0, 0, 0.2) !important;
  z-index: 10 !important;
  box-sizing: border-box !important;
  white-space: nowrap;
  margin: 0 auto;
  top: 0;
  
  /* Styles spécifiques pour les rendez-vous qui se chevauchent */
  border-right: 1px solid rgba(255, 255, 255, 0.2) !important;
  border-top: 1px solid rgba(255, 255, 255, 0.2) !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.appointment:hover {
  transform: translateY(-2px) !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15) !important;
  z-index: 20 !important; /* Priorité plus élevée au survol */
}

.appointment-time {
  font-size: 0.7rem !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  width: 100% !important;
}

.appointment-client {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  max-width: 100% !important;
  width: 100% !important;
}

.appointment-type {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  width: 100% !important;
  display: none !important; /* Masquer le type sur les appareils mobiles ou écrans étroits */
}

.appointment.consultation {
  background-color: var(--primary-color);
  border-left-color: var(--primary-hover);
}

.appointment.suivi {
  background-color: var(--success-color);
  border-left-color: #4ca64c;
}

.appointment.traitement {
  background-color: var(--warning-color);
  border-left-color: #d9a441;
}

.appointment.urgence {
  background-color: var(--danger-color);
  border-left-color: #c9302c;
}

.appointment-dragging {
  opacity: 0.7;
  transform: scale(0.95);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

/* Améliorer la lisibilité pour les rendez-vous étroits */
@media (max-width: 768px), (max-width: 1024px) {
  .appointment {
    padding: 3px 4px !important;
    font-size: 0.75rem !important;
  }
  
  .appointment-time {
    font-size: 0.7rem !important;
  }
}

/* Adapter les rendez-vous très étroits (quand 2+ rendez-vous se chevauchent) */
@media (min-width: 769px) {
  /* 2 rendez-vous qui se chevauchent */
  .appointment[style*="width: 49%"] .appointment-type,
  .appointment[style*="width: 48%"] .appointment-type,
  .appointment[style*="width: 47%"] .appointment-type {
    font-size: 0.75rem !important;
  }
  
  /* 3 rendez-vous qui se chevauchent */
  .appointment[style*="width: 33%"] .appointment-type,
  .appointment[style*="width: 32%"] .appointment-type,
  .appointment[style*="width: 31%"] .appointment-type {
    display: none !important;
  }
  
  .appointment[style*="width: 33%"] .appointment-client,
  .appointment[style*="width: 32%"] .appointment-client,
  .appointment[style*="width: 31%"] .appointment-client {
    font-size: 0.75rem !important;
  }
  
  /* 4+ rendez-vous qui se chevauchent */
  .appointment[style*="width: 24%"] .appointment-client,
  .appointment[style*="width: 23%"] .appointment-client,
  .appointment[style*="width: 19%"] .appointment-client {
    font-size: 0.7rem !important;
    white-space: nowrap !important;
  }
  
  /* Très petit espace (5+ rendez-vous) */
  .appointment[style*="width: 19%"] .appointment-time,
  .appointment[style*="width: 18%"] .appointment-time,
  .appointment[style*="width: 17%"] .appointment-time,
  .appointment[style*="width: 16%"] .appointment-time {
    font-size: 0.65rem !important;
  }
}

/* Style spécifique pour les rendez-vous qui se chevauchent */
.appointment[style*="width: 49%"],
.appointment[style*="width: 33%"],
.appointment[style*="width: 24%"],
.appointment[style*="width: 19%"] {
  margin: 0 !important; /* Supprimer les marges pour les rendez-vous qui se chevauchent */
  box-sizing: border-box !important;
  border-top-right-radius: 2px !important;
  border-bottom-right-radius: 2px !important;
}

.more-appointments {
  font-size: 0.8rem;
  color: #777;
  text-align: center;
  background-color: #f5f5f5;
  padding: 2px 5px;
  border-radius: 3px;
  margin-top: 3px;
  cursor: pointer;
}

.more-appointments:hover {
  background-color: #eee;
}

/* ========== INDICATEUR D'HEURE ACTUELLE ========== */
.current-time-indicator {
  position: absolute !important;
  left: 0 !important;
  right: 0 !important;
  height: 2px !important;
  background-color: #e74c3c !important;
  z-index: 10 !important;
  pointer-events: none !important;
  animation: pulse 2s infinite !important;
}

.current-time-indicator::before {
  content: "" !important;
  position: absolute !important;
  left: -5px !important;
  top: -4px !important;
  width: 10px !important;
  height: 10px !important;
  background-color: #e74c3c !important;
  border-radius: 50% !important;
  box-shadow: 0 0 0 2px white !important;
}

/* ========== MODAL ========== */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(3px);
  animation: modalFadeIn 0.3s forwards;
}

.modal-content {
  background-color: white;
  padding: 25px;
  border-radius: 8px;
  width: 90%;
  max-width: 550px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
  position: relative;
  animation: modalSlideIn 0.3s forwards;
}

.close-modal {
  position: absolute;
  top: 15px;
  right: 20px;
  font-size: 24px;
  color: #999;
  cursor: pointer;
  transition: color 0.2s;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.close-modal:hover {
  color: #333;
  background-color: #f5f5f5;
}

.modal h2 {
  margin-bottom: 20px;
  color: var(--primary-color);
  font-size: 1.5rem;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--border-color);
}

/* ========== FORMULAIRES ========== */
.form-row {
  display: flex;
  gap: 20px;
  margin-bottom: 15px;
}

.form-group {
  margin-bottom: 15px;
  position: relative;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #555;
  font-size: 0.9rem;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 1rem;
  transition: border-color 0.2s, box-shadow 0.2s;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 0 3px rgba(198, 178, 145, 0.2);
  outline: none;
}

.form-group input.error,
.form-group select.error,
.form-group textarea.error {
  border-color: var(--danger-color);
}

.error-message {
  color: var(--danger-color);
  font-size: 0.8rem;
  margin-top: 5px;
}

.time-selector {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}

.time-picker {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.time-picker-label {
  font-weight: 500;
  font-size: 0.9rem;
}

.time-picker-value {
  font-size: 1.5rem;
  font-weight: 600;
  padding: 5px 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100px;
  text-align: center;
}

.time-picker-controls {
  display: flex;
  gap: 5px;
}

.time-control-btn {
  width: 30px;
  height: 30px;
  border: 1px solid #ddd;
  background-color: white;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 1.2rem;
  transition: all 0.2s;
}

.time-control-btn:hover {
  background-color: #f5f5f5;
}

.time-duration {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 20px;
}

.time-range-preview {
  padding: 5px 10px;
  background-color: #f5f5f5;
  border-radius: 4px;
  font-weight: 500;
  color: var(--primary-color);
  font-size: 0.9rem;
  margin-left: 15px;
}

.client-suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid #ddd;
  border-top: none;
  border-radius: 0 0 6px 6px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 10;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.client-suggestion-item {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s;
  border-bottom: 1px solid #f5f5f5;
}

.client-suggestion-item:hover {
  background-color: #f5f5f5;
}

.client-suggestion-item:last-child {
  border-bottom: none;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 25px;
  padding-top: 15px;
  border-top: 1px solid var(--border-color);
}

.submit-btn {
  padding: 10px 20px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s;
  font-weight: 600;
}

.submit-btn:hover {
  background-color: var(--primary-hover);
  transform: translateY(-1px);
}

.cancel-btn {
  padding: 10px 20px;
  background-color: white;
  color: #666;
  border: 1px solid #ddd;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s;
}

.cancel-btn:hover {
  background-color: #f5f5f5;
}

.delete-btn {
  padding: 10px 20px;
  background-color: #fff;
  color: var(--danger-color);
  border: 1px solid var(--danger-color);
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s;
  margin-right: auto;
}

.delete-btn:hover {
  background-color: var(--danger-color);
  color: white;
}

/* ========== SETTINGS ========== */
.settings-group {
  margin-bottom: 20px;
  padding: 15px;
  background-color: #fafafa;
  border-radius: 6px;
}

.settings-group h3 {
  margin-bottom: 15px;
  color: #555;
  font-size: 1.1rem;
}

.days-selection {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.day-checkbox {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  min-width: 120px;
  transition: all 0.2s;
}

.day-checkbox:hover {
  background-color: #f5f5f5;
}

.day-checkbox input[type="checkbox"] {
  width: 18px;
  height: 18px;
  margin: 0;
  margin-right: 5px;
}

.day-checkbox label {
  margin: 0;
  cursor: pointer;
  margin-bottom: 0;
  display: inline-block;
}

/* ========== NOTIFICATIONS ========== */
.notification {
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 12px 20px;
  border-radius: 6px;
  background-color: white;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  animation: slideInRight 0.3s forwards, fadeOut 0.3s 2.7s forwards;
  display: flex;
  align-items: center;
  font-weight: 500;
}

.notification.success {
  border-left: 4px solid var(--success-color);
}

.notification.error {
  border-left: 4px solid var(--danger-color);
}

.notification.warning {
  border-left: 4px solid var(--warning-color);
}

.notification.info {
  border-left: 4px solid var(--info-color);
}

.notification:before {
  content: '';
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 10px;
}

.notification.success:before {
  background-color: var(--success-color);
}

.notification.error:before {
  background-color: var(--danger-color);
}

.notification.warning:before {
  background-color: var(--warning-color);
}

.notification.info:before {
  background-color: var(--info-color);
}

/* ========== ANNÉE ========== */
#year-view {
  overflow-y: auto !important;
  height: 100% !important;
}

/* ========== ANIMATIONS ========== */
@keyframes pulse {
  0% {
    opacity: 0.7;
    box-shadow: 0 0 0 0 rgba(231, 76, 60, 0.4);
  }
  50% {
    opacity: 1;
  }
  70% {
    box-shadow: 0 0 0 5px rgba(231, 76, 60, 0);
  }
  100% {
    opacity: 0.7;
    box-shadow: 0 0 0 0 rgba(231, 76, 60, 0);
  }
}

@keyframes slideLeft {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
    background-color: rgba(198, 178, 145, 0.4);
  }
  100% {
    transform: scale(40, 40);
    opacity: 0;
    box-shadow: 0 0 0 0 rgba(231, 76, 60, 0);
    background-color: rgba(198, 178, 145, 0.2);
  }
}

@keyframes slideRight {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    opacity: 0;
  }
}

@keyframes zoomIn {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.1);
    opacity: 0;
  }
}

@keyframes zoomOut {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.9);
    opacity: 0;
  }
}

@keyframes fade {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes slideInRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes modalFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes modalSlideIn {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes selectPulse {
  0% {
    background-color: rgba(198, 178, 145, 0.2);
  }
  50% {
    background-color: rgba(198, 178, 145, 0.4);
  }
  100% {
    background-color: rgba(198, 178, 145, 0.2);
  }
}

@keyframes ripple {
  0% {
    transform: scale(0, 0);
    opacity: 0.5;
  }
  20% {
    transform: scale(25, 25);
    opacity: 0.3;
  }
  100% {
    transform: scale(50, 50);
    opacity: 0;
  }
}

/* ========== RESPONSIVE ========== */
@media (max-width: 768px) {
  .agenda-controls {
    flex-direction: column;
    align-items: stretch;
  }
  
  .date-navigation {
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .current-date {
    order: -1;
    width: 100%;
    margin-bottom: 10px;
  }
  
  .week-days {
    flex-wrap: nowrap;
    overflow-x: auto;
  }
  
  .week-day {
    min-width: 120px;
  }
  
  .form-row {
    flex-direction: column;
    gap: 10px;
  }
  
  .month-day-mini {
    font-size: 0.8rem;
  }
  
  .month-header h3 {
    font-size: 1rem;
  }
  
  .day-name {
    font-size: 0.7rem;
  }
  
  .day-date {
    font-size: 1rem;
  }
  
  .day-month {
    font-size: 0.7rem;
  }
  
  .appointment {
    padding: 3px 5px;
    font-size: 0.75rem;
  }
  
  .time-header-cell,
  .time-column {
    width: 50px;
    min-width: 50px;
  }
}

/* Styles pour améliorer le cliquer-glisser dans l'agenda */
.time-cell {
  user-select: none;
  position: relative;
  cursor: pointer;
  transition: background-color 0.15s;
}

.time-cell:hover {
  background-color: rgba(198, 178, 145, 0.1);
}

.time-cell.selected {
  background-color: rgba(198, 178, 145, 0.3);
  position: relative;
}

/* Visual feedback pendant la sélection */
.time-cell.selected::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(198, 178, 145, 0.15);
  pointer-events: none;
  animation: pulse-selection 1.5s infinite;
}

@keyframes pulse-selection {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.5;
  }
}

/* Style pour l'indicateur de sélection */
.selection-indicator {
  position: absolute;
  right: 5px;
  bottom: 5px;
  background-color: rgba(198, 178, 145, 0.8);
  color: white;
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 0.7rem;
  pointer-events: none;
  opacity: 0.9;
  z-index: 5;
}

/* Améliorations pour l'interaction tactile */
@media (max-width: 768px) {
  .time-cell {
    min-height: 40px; /* Facilite le toucher sur mobile */
  }
  
  .time-cell.selected::after {
    border: 2px solid rgba(198, 178, 145, 0.8);
  }
}

/* Styles pour la gestion des heures de fermeture */
.day-checkbox-container {
  display: flex;
  flex-direction: column;
  min-width: 180px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 10px;
  margin-bottom: 10px;
}

.day-checkbox {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 5px;
}

.day-checkbox input[type="checkbox"] {
  width: 18px;
  height: 18px;
  margin: 0;
}

.day-checkbox label {
  margin: 0;
  cursor: pointer;
  font-weight: 500;
  margin-bottom: 0;
  display: inline-block;
}

.closing-hours-btn {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 0.85rem;
  cursor: pointer;
  transition: all 0.2s;
  margin-top: 5px;
  color: #666;
}

.closing-hours-btn:hover {
  background-color: #e5e5e5;
}

.closing-hours-preview {
  margin-top: 8px;
  font-size: 0.85rem;
  color: #777;
}

.closing-range {
  display: inline-block;
  background-color: #f0f0f0;
  padding: 3px 8px;
  border-radius: 3px;
  margin-right: 5px;
  margin-bottom: 5px;
  border: 1px solid #ddd;
}

/* Panneau des heures de fermeture */
.closing-hours-panel {
  margin-top: 15px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 6px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.closing-hours-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #ddd;
}

.closing-hours-header h4 {
  margin: 0;
  font-size: 1rem;
  color: #333;
}

.close-panel-btn {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #777;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  padding: 0;
}

.closing-hours-content {
  padding: 15px;
}

.no-ranges {
  font-style: italic;
  color: #777;
  text-align: center;
  margin: 10px 0;
}

.closing-range-item {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.closing-range-item:last-child {
  margin-bottom: 15px;
  padding-bottom: 0;
  border-bottom: none;
}

.closing-range-times {
  display: flex;
  align-items: flex-end;
  gap: 10px;
}

.closing-range-times .form-group {
  margin-bottom: 0;
  flex: 1;
}

.remove-range-btn {
  background-color: white;
  color: #d9534f;
  border: 1px solid #d9534f;
  border-radius: 4px;
  padding: 8px 12px;
  cursor: pointer;
  transition: all 0.2s;
  font-size: 0.85rem;
}

.remove-range-btn:hover {
  background-color: #d9534f;
  color: white;
}

.add-range-btn {
  background-color: #f5f5f5;
  border: 1px dashed #ccc;
  border-radius: 4px;
  padding: 10px;
  width: 100%;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s;
  color: #666;
}

.add-range-btn:hover {
  background-color: #ebebeb;
  border-color: #999;
}

/* Style pour les créneaux horaires fermés */
.time-cell.closed-time {
  background-color: rgba(211, 211, 211, 0.5);
  background-image: repeating-linear-gradient(
    45deg,
    transparent,
    transparent 5px,
    rgba(0, 0, 0, 0.05) 5px,
    rgba(0, 0, 0, 0.05) 10px
  );
  cursor: not-allowed;
  position: relative;
  overflow: hidden;
}

.time-cell.closed-time::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.4);
  z-index: 1;
}

.time-cell.closed-time::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(211, 211, 211, 0.2);
  z-index: 2;
}

/* Indicateur de fermeture */
.closed-indicator {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.8);
  color: #777;
  padding: 3px 8px;
  border-radius: 3px;
  font-size: 0.8rem;
  z-index: 3;
  white-space: nowrap;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  pointer-events: none;
}

/* Styles responsifs */
@media (max-width: 768px) {
  .day-checkbox-container {
    min-width: 100%;
  }
  
  .closing-range-times {
    flex-direction: column;
    align-items: stretch;
    gap: 5px;
  }
  
  .closing-range-times .form-group {
    margin-bottom: 5px;
  }
}

/* Améliorer les retours visuels pour la sélection */
.time-cell.selected {
  background-color: rgba(198, 178, 145, 0.3);
  position: relative;
  z-index: 1;
}

.time-cell.selecting {
  animation: selectPulse 0.8s infinite;
}

@keyframes selectPulse {
  0% {
    background-color: rgba(198, 178, 145, 0.2);
  }
  50% {
    background-color: rgba(198, 178, 145, 0.4);
  }
  100% {
    background-color: rgba(198, 178, 145, 0.2);
  }
}

/* Indicateur de durée pendant la sélection */
.time-cell.selected::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 1;
  box-shadow: inset 0 0 0 2px rgba(198, 178, 145, 0.5);
}

.time-cell.selected:first-of-type::after {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-top: 2px solid rgba(198, 178, 145, 0.8);
}

.time-cell.selected:last-of-type::after {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom: 2px solid rgba(198, 178, 145, 0.8);
}

/* Améliorer l'effet hover sur les cellules de temps */
.time-cell:hover {
  background-color: rgba(198, 178, 145, 0.1);
  cursor: pointer;
  transition: background-color 0.1s ease;
}

/* Optimisations pour le tactile */
@media (max-width: 768px) {
  .time-cell {
    min-height: 44px; /* Rendre les cellules plus grandes pour faciliter le toucher */
  }
  
  .time-cell.selected::after {
    box-shadow: inset 0 0 0 3px rgba(198, 178, 145, 0.6); /* Bordure plus épaisse sur mobile */
  }

  /* Améliorer la visibilité des sélections sur petit écran */
  .time-cell.selected {
    background-color: rgba(198, 178, 145, 0.4);
  }
}

/* Ajouter une transition plus fluide pour le glisser-déposer */
.appointment {
  transition: transform 0.2s ease, box-shadow 0.2s ease, opacity 0.2s ease;
}

.appointment-dragging {
  opacity: 0.65;
  transform: scale(0.95);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  z-index: 100;
}

/* Améliorer le retour visuel pour le survol d'une cellule pendant le drag */
.time-cell.drag-over {
  background-color: rgba(198, 178, 145, 0.3);
  box-shadow: inset 0 0 0 2px rgba(198, 178, 145, 0.7);
  animation: dragOverPulse 1s infinite;
}

@keyframes dragOverPulse {
  0% {
    box-shadow: inset 0 0 0 2px rgba(198, 178, 145, 0.5);
  }
  50% {
    box-shadow: inset 0 0 0 3px rgba(198, 178, 145, 0.8);
  }
  100% {
    box-shadow: inset 0 0 0 2px rgba(198, 178, 145, 0.5);
  }
}

/* Ajouter un indicateur de durée pendant la sélection */
.selection-duration {
  position: absolute;
  bottom: 5px;
  right: 5px;
  background-color: rgba(198, 178, 145, 0.9);
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.7rem;
  pointer-events: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

/* Styles pour les boutons du scanner de code-barres */
.barcode-actions {
  display: flex;
  gap: 10px;
  margin-left: 15px;
}

.scanner-btn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #c6b291;
  color: white;
  border: none;
  cursor: pointer;
  transition: all 0.2s;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.scanner-btn:hover {
  background-color: #b7a382;
  transform: translateY(-2px);
}

.scanner-icon {
  font-size: 1.2rem;
  font-style: normal;
}

/* Modification de la disposition des filtres */
.filters-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

/* Pour la page Caisse */
.scan-product-btn {
  padding: 8px 15px;
  background-color: #c6b291;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-left: 10px;
  transition: background-color 0.3s;
}

.scan-product-btn:hover {
  background-color: #b7a382;
}

.scan-product-btn::before {
  content: '📷';
  font-size: 1.2rem;
}

/* Style pour les articles avec un faible stock */
.stock-alert {
  background-color: rgba(255, 0, 0, 0.1);
  border-left: 3px solid #d9534f;
}

/* Badge indiquant le nombre d'articles en rupture de stock */
.stock-alert-badge {
  background-color: #d9534f;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
}

/* Assurer que le type de prestation est toujours affiché */
.appointment-type {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  width: 100% !important;
  display: block !important; /* Modifié de "none" à "block" */
  font-size: 0.8rem !important;
  opacity: 0.9 !important;
}

/* Augmenter légèrement la hauteur minimale des rendez-vous */
.appointment {
  min-height: 50px !important;
}

/* Styles pour améliorer la lisibilité dans les rendez-vous étroits */
@media (min-width: 769px) {
  /* Pour les rendez-vous côte à côte */
  .appointment[style*="width: 49%"] .appointment-client,
  .appointment[style*="width: 48%"] .appointment-client,
  .appointment[style*="width: 47%"] .appointment-client {
    font-size: 0.8rem !important;
  }
  
  .appointment[style*="width: 49%"] .appointment-type,
  .appointment[style*="width: 48%"] .appointment-type,
  .appointment[style*="width: 47%"] .appointment-type {
    font-size: 0.75rem !important;
  }
  
  /* Pour 3+ rendez-vous qui se chevauchent */
  .appointment[style*="width: 33%"] .appointment-client,
  .appointment[style*="width: 32%"] .appointment-client,
  .appointment[style*="width: 31%"] .appointment-client {
    font-size: 0.75rem !important;
  }
  
  .appointment[style*="width: 33%"] .appointment-type,
  .appointment[style*="width: 32%"] .appointment-type,
  .appointment[style*="width: 31%"] .appointment-type {
    font-size: 0.7rem !important;
  }
}