/* ClientsList.css */
.clients-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.clients-table th,
.clients-table td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #eee;
  vertical-align: middle;
}

.clients-table th {
  background-color: #f5f5f5;
  font-weight: 600;
  color: #555;
}

.clients-table tr:hover {
  background-color: #f9f9f9;
}

.clients-table tr:last-child td {
  border-bottom: none;
}

/* Style pour la photo miniature du client */
.client-photo-small {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  color: #999;
  font-size: 16px;
}

.client-photo-small img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Styles pour les boutons d'action */
.action-btns {
  display: flex;
  gap: 8px;
}

.action-btn {
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 0.85rem;
  cursor: pointer;
  transition: all 0.2s;
}

.edit-btn {
  background-color: #c6b291;
  color: white;
  border: none;
}

.edit-btn:hover {
  background-color: #b7a382;
}

.delete-btn {
  background-color: white;
  color: #d9534f;
  border: 1px solid #d9534f;
}

.delete-btn:hover {
  background-color: #d9534f;
  color: white;
}

/* Import/Export Section */
.import-export-container {
  margin: 20px 0;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.import-export-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #5bc0de;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 15px;
  cursor: pointer;
  transition: background-color 0.2s;
  font-size: 0.9rem;
}

.import-export-btn:hover {
  background-color: #46b8da;
}

/* Styles responsive */
@media (max-width: 768px) {
  .clients-table {
    display: block;
    overflow-x: auto;
  }
  
  .client-photo-small {
    width: 35px;
    height: 35px;
  }
  
  .action-btns {
    flex-direction: column;
    gap: 5px;
  }
  
  .action-btn {
    padding: 5px 10px;
    font-size: 0.8rem;
  }
}

/* Styles pour les indicateurs de synchronisation dans la liste */
.sync-status-icon {
  display: inline-block;
  text-align: center;
}

.sync-status-icon.synced {
  color: #28a745;
}

.sync-status-icon.not-synced {
  color: #ffc107;
}