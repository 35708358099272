/* ClientForm.css */
.client-form {
  background-color: white;
  border-radius: 8px;
  padding: 25px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.form-layout {
  display: flex;
  gap: 30px;
  margin-bottom: 20px;
}

.form-columns {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.form-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* Style pour la section photo */
.photo-section {
  flex: 0 0 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.photo-upload {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.photo-preview {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 2px dashed #ddd;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;
  cursor: pointer;
  transition: all 0.2s;
}

.photo-preview:hover {
  border-color: #c6b291;
  background-color: #f5f5f5;
}

.photo-icon {
  font-size: 48px;
  color: #ccc;
}

.photo-btn {
  padding: 8px 12px;
  background-color: #f5f5f5;
  color: #555;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  width: 100%;
  text-align: center;
  transition: all 0.2s;
}

.photo-btn:hover {
  background-color: #e5e5e5;
}

/* Style pour les champs du formulaire */
.form-fields {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-group {
  margin-bottom: 5px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
  color: #555;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 0.95rem;
  transition: border-color 0.2s, box-shadow 0.2s;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  border-color: #c6b291;
  box-shadow: 0 0 0 3px rgba(198, 178, 145, 0.2);
  outline: none;
}

.form-group.full-width {
  width: 100%;
}

.form-group textarea {
  resize: vertical;
  min-height: 70px;
}

/* Section pour les réseaux sociaux */
.social-media-container {
  border: 1px solid #eee;
  border-radius: 8px;
  padding: 15px;
  margin-top: 15px;
  background-color: #fafafa;
}

.social-media-container h3 {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 1rem;
  color: #555;
  border-bottom: 1px solid #eee;
  padding-bottom: 8px;
}

.social-networks {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.social-network {
  display: flex;
  align-items: center;
  gap: 10px;
}

.social-network-icon {
  width: 24px;
  text-align: center;
  font-size: 1.2rem;
  color: #777;
}

.social-network-input {
  flex: 1;
}

.add-social-btn {
  margin-top: 10px;
  padding: 6px 12px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: #555;
  font-size: 0.9rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  transition: all 0.2s;
}

.add-social-btn:hover {
  background-color: #e0e0e0;
}

.remove-social-btn {
  background: none;
  border: none;
  color: #d9534f;
  cursor: pointer;
  font-size: 1.1rem;
  padding: 0;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
}

.remove-social-btn:hover {
  transform: scale(1.1);
}

/* Section notes et informations supplémentaires */
.additional-info {
  border: 1px solid #eee;
  border-radius: 8px;
  padding: 15px;
  margin-top: 15px;
  background-color: #fafafa;
}

.additional-info h3 {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 1rem;
  color: #555;
  border-bottom: 1px solid #eee;
  padding-bottom: 8px;
}

/* Boutons d'action */
.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 25px;
  padding-top: 15px;
  border-top: 1px solid #eee;
}

.submit-btn {
  padding: 10px 20px;
  background-color: #c6b291;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
  font-weight: 600;
}

.submit-btn:hover {
  background-color: #b7a382;
}

.reset-btn {
  padding: 10px 20px;
  background-color: white;
  color: #666;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
}

.reset-btn:hover {
  background-color: #f5f5f5;
}

/* Styles responsive */
@media (max-width: 768px) {
  .form-columns {
    flex-direction: column;
  }
  
  .photo-section {
    flex: none;
    margin-bottom: 20px;
  }

  .form-actions {
    flex-wrap: wrap;
  }
  
  .submit-btn, .reset-btn {
    width: 100%;
    margin-bottom: 10px;
  }
}