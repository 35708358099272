/* Stock.css - Styles pour les composants liés à la gestion de stock */

/* ===== STYLES COMMUNS ===== */
.tab-content-container {
  margin-top: 20px;
}

.tab-content {
  display: none;
}

.tab-content.active {
  display: block;
}

/* ===== STYLES DE LA LISTE DES ARTICLES ===== */
.stock-list {
  width: 100%;
}

.no-items {
  padding: 30px;
  text-align: center;
  background-color: #f9f9f9;
  border-radius: 8px;
  border: 1px dashed #ddd;
  color: #777;
  font-style: italic;
}

.stock-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  width: 100%;
}

/* Carte d'article */
.stock-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s, box-shadow 0.2s;
  display: flex;
  flex-direction: column;
  overflow: visible; /* Changé de 'hidden' à 'visible' */
}

.stock-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.stock-header {
  padding: 15px;
  display: flex;
  align-items: center;
  gap: 15px;
  border-bottom: 1px solid #f0f0f0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  overflow: hidden;
}

.stock-image {
  width: 60px;
  height: 60px;
  border-radius: 8px;
  overflow: hidden;
  flex-shrink: 0;
  background-color: #f0f0f0;
}

.stock-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.placeholder-image {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 24px;
  font-weight: bold;
  background-color: #c6b291;
}

.stock-title {
  flex-grow: 1;
}

.stock-title h3 {
  margin: 0 0 5px 0;
  font-size: 1.1rem;
  color: #333;
}

.stock-ref {
  font-size: 0.8rem;
  color: #777;
  margin-bottom: 5px;
}

.stock-category {
  margin-top: 5px;
}

.category-badge {
  display: inline-block;
  padding: 3px 8px;
  background-color: #f0f0f0;
  border-radius: 4px;
  font-size: 0.8rem;
  color: #666;
}

.stock-details {
  padding: 15px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.stock-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 15px;
}

.stock-quantity {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 15px;
  border-radius: 6px;
  background-color: #f5f5f5;
  min-width: 100px;
}

.stock-quantity-label {
  font-size: 0.85rem;
  color: #666;
}

.stock-quantity-value {
  font-size: 1.5rem;
  font-weight: 600;
}

/* Styles des niveaux de stock */
.stock-level-normal {
  background-color: #d4edda;
  color: #155724;
}

.stock-level-low {
  background-color: #fff3cd;
  color: #856404;
}

.stock-level-critical {
  background-color: #f8d7da;
  color: #721c24;
}

.stock-level-empty {
  background-color: #f5f5f5;
  color: #dc3545;
}

.stock-min-max {
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex-grow: 1;
}

.stock-min, .stock-price, .stock-supplier, .stock-location {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  border-bottom: 1px solid #f0f0f0;
}

.stock-label {
  font-size: 0.85rem;
  color: #777;
  margin-right: 5px;
}

.stock-value {
  font-weight: 500;
}

.stock-description {
  padding-top: 10px;
  border-top: 1px solid #f0f0f0;
}

.stock-description p {
  margin: 0;
  font-size: 0.9rem;
  color: #666;
  line-height: 1.4;
}

.stock-supplier-name, .stock-location-value {
  font-weight: 500;
}

/* Historique des mouvements */
.stock-history {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #f0f0f0;
}

.stock-history h4 {
  margin: 0 0 10px 0;
  font-size: 1rem;
  color: #555;
}

.history-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.85rem;
}

.history-table th,
.history-table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #eee;
}

.history-table th {
  background-color: #f5f5f5;
  font-weight: 600;
}

.positive {
  color: #28a745;
}

.negative {
  color: #dc3545;
}

.stock-actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
  padding: 15px;
  background-color: #f9f9f9;
  border-top: 1px solid #f0f0f0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  overflow: hidden;
}

.action-btn {
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
  font-size: 0.9rem;
  flex-grow: 1;
  text-align: center;
}

.stock-update-btn {
  background-color: #c6b291;
  color: white;
  border: none;
  flex-basis: 100%;
}

.stock-update-btn:hover {
  background-color: #b7a382;
}

.stock-history-btn {
  background-color: white;
  color: #5bc0de;
  border: 1px solid #5bc0de;
}

.stock-history-btn:hover {
  background-color: #5bc0de;
  color: white;
}

.edit-btn {
  background-color: #c6b291;
  color: white;
  border: none;
}

.edit-btn:hover {
  background-color: #b7a382;
}

.delete-btn {
  background-color: white;
  color: #d9534f;
  border: 1px solid #d9534f;
}

.delete-btn:hover {
  background-color: #d9534f;
  color: white;
}

/* ===== STYLES DU FORMULAIRE DE STOCK ===== */
.stock-form {
  background-color: white;
  padding: 25px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.form-layout {
  display: flex;
  gap: 30px;
  margin-bottom: 20px;
}

.form-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-row {
  display: flex;
  gap: 15px;
}

.form-group {
  margin-bottom: 5px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #555;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 0.95rem;
  transition: border-color 0.2s, box-shadow 0.2s;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  border-color: #c6b291;
  box-shadow: 0 0 0 3px rgba(198, 178, 145, 0.2);
  outline: none;
}

.form-group input.error,
.form-group select.error,
.form-group textarea.error {
  border-color: #d9534f;
}

.error-message {
  color: #d9534f;
  font-size: 0.8rem;
  margin-top: 5px;
}

.price-input {
  position: relative;
}

.price-input input {
  padding-right: 30px;
}

.price-symbol {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #777;
  font-weight: 500;
}

.quantity-info {
  margin-top: 5px;
  font-size: 0.85rem;
  color: #666;
  font-style: italic;
}

.image-upload-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.image-preview {
  width: 100%;
  height: 150px;
  border: 2px dashed #ddd;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
  background-color: #f9f9f9;
  transition: all 0.2s;
}

.image-preview:hover {
  border-color: #c6b291;
  background-color: #f5f5f5;
}

.no-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  color: #888;
}

.image-icon {
  font-size: 30px;
}

.preview-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-btn {
  padding: 8px 15px;
  background-color: #f5f5f5;
  color: #555;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  align-self: flex-start;
}

.image-btn:hover {
  background-color: #e5e5e5;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 25px;
  padding-top: 20px;
  border-top: 1px solid #eee;
}

.submit-btn {
  padding: 10px 20px;
  background-color: #c6b291;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-weight: 600;
}

.submit-btn:hover {
  background-color: #b7a382;
}

.cancel-btn {
  padding: 10px 20px;
  background-color: white;
  color: #666;
  border: 1px solid #ddd;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.cancel-btn:hover {
  background-color: #f5f5f5;
}

/* Modal de mise à jour de stock */
.stock-update-modal {
  max-width: 500px;
}

.stock-update-modal h3 {
  margin-top: 5px;
  margin-bottom: 20px;
  color: #c6b291;
  font-size: 1.2rem;
}

/* Styles pour l'infobulle dans l'historique des mouvements */
.tooltip-container {
  position: relative;
  max-width: 150px; /* Limiter la largeur de la cellule */
  cursor: pointer;
}

.note-text {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.tooltip-text {
  position: absolute;
  z-index: 100;
  background-color: #333;
  color: white;
  text-align: left;
  padding: 10px;
  border-radius: 6px;
  width: 250px; /* Un peu plus large pour plus de contenu */
  top: -10px; /* Positionnement par rapport au haut */
  left: 100%; /* Positionnement à droite de l'élément parent */
  transform: translateY(-100%); /* Décalage vers le haut */
  margin-left: 10px; /* Espacement avec l'élément parent */
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s;
  white-space: normal;
  word-wrap: break-word;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  font-size: 0.9rem;
  line-height: 1.4;
}

/* Repositionner la flèche pour qu'elle pointe à gauche */
.tooltip-text::after {
  content: "";
  position: absolute;
  top: 30%;
  right: 100%; /* Flèche à gauche de l'infobulle */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #333 transparent transparent; /* Flèche pointant vers la gauche */
}

/* Afficher l'infobulle au survol */
.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

/* ===== STYLES RESPONSIFS ===== */
@media (max-width: 768px) {
  .form-layout {
    flex-direction: column;
    gap: 15px;
  }
  
  .form-row {
    flex-direction: column;
    gap: 15px;
  }
  
  .stock-grid {
    grid-template-columns: 1fr;
  }
  
  .stock-info {
    flex-direction: column;
  }
  
  .stock-actions {
    flex-direction: column;
  }
  
  .action-btn {
    flex-grow: 1;
    flex-basis: auto;
  }
  
  .tooltip-text {
    width: 180px;
    left: auto;
    right: 0;
    transform: translateY(-100%);
    margin-left: 0;
  }
  
  .tooltip-text::after {
    left: 15px;
    right: auto;
    top: 100%;
    border-color: #333 transparent transparent transparent;
  }
}

/* Styles pour le modal d'historique avec arrière-plan flouté */
.history-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1500;
  padding: 20px;
}

.history-modal-content {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  padding: 25px;
  animation: fadeIn 0.3s ease-out;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
}

.history-modal-content h3 {
  color: #c6b291;
  margin: 0 0 20px 0;
  padding-bottom: 15px;
  border-bottom: 1px solid #eee;
  font-size: 1.4rem;
}

.close-history-modal {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  font-size: 24px;
  color: #777;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: background-color 0.2s;
}

.close-history-modal:hover {
  background-color: #f5f5f5;
  color: #333;
}

.history-table-container {
  overflow-x: auto;
}

.history-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.9rem;
}

.history-table th,
.history-table td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #eee;
}

.history-table th {
  background-color: #f5f5f5;
  font-weight: 600;
  position: sticky;
  top: 0;
  z-index: 10;
}

.history-table tr:hover {
  background-color: #f9f9f9;
}

.history-note {
  max-width: 300px;
  white-space: normal;
  word-wrap: break-word;
}

.no-history {
  text-align: center;
  color: #777;
  font-style: italic;
  padding: 30px;
}

/* Styles responsifs pour le modal d'historique */
@media (max-width: 768px) {
  .history-modal-content {
    width: 95%;
    padding: 15px;
  }
  
  .history-table th,
  .history-table td {
    padding: 10px;
    font-size: 0.85rem;
  }
  
  .history-note {
    max-width: 150px;
  }
}

/* Styles pour les sous-catégories */
.subcategories-container {
  margin-top: 15px;
  padding-top: 10px;
  border-top: 1px dashed #e0e0e0;
}

.subcategories-container h4 {
  font-size: 0.95rem;
  color: #666;
  margin-bottom: 8px;
}

.subcategory-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.subcategory-btn {
  padding: 6px 12px;
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 0.85rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.subcategory-btn:hover {
  background-color: #f0f0f0;
}

.subcategory-btn.active {
  background-color: #e0e7f1;
  color: #5bc0de;
  border-color: #5bc0de;
}

/* Badge pour les sous-catégories */
.subcategory-badge {
  display: inline-block;
  padding: 2px 6px;
  background-color: #e0e7f1;
  color: #5bc0de;
  border-radius: 4px;
  font-size: 0.75rem;
  margin-left: 5px;
}

/* Styles pour les sous-catégories dans le formulaire */
.sous-categorie-group {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px dashed #e0e0e0;
}

/* Ajouter dans Stock.css et Synthese.css */

.sync-status-indicator {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f9fa;
  border-radius: 5px;
  padding: 10px 15px;
  margin-bottom: 20px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  font-size: 14px;
}

.sync-button {
  background-color: #c6b291;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 15px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
}

.sync-button:hover {
  background-color: #b9a583;
}

.sync-button:disabled {
  background-color: #d9d9d9;
  cursor: not-allowed;
}