/* UserProfile.css */
.user-profile-container {
    background-color: white;
    border-radius: 8px;
    padding: 25px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    max-width: 700px;
    margin: 0 auto;
  }
  
  .user-profile-container h2 {
    color: #c6b291;
    margin-bottom: 20px;
    padding-bottom: 15px;
    border-bottom: 1px solid #eee;
  }
  
  .profile-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
    color: #555;
  }
  
  .form-group input {
    width: 100%;
    padding: 12px 15px;
    border: 1px solid #ddd;
    border-radius: 6px;
    font-size: 1rem;
    transition: border-color 0.3s, box-shadow 0.3s;
  }
  
  .form-group input:focus {
    border-color: #c6b291;
    box-shadow: 0 0 0 3px rgba(198, 178, 145, 0.2);
    outline: none;
  }
  
  .password-section {
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 20px;
    margin-top: 10px;
    border: 1px solid #eee;
  }
  
  .password-section h3 {
    margin-top: 0;
    margin-bottom: 15px;
    color: #555;
    font-size: 1.1rem;
  }
  
  .password-info {
    color: #777;
    font-size: 0.9rem;
    font-style: italic;
    margin-bottom: 15px;
  }
  
  .form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 25px;
  }
  
  .submit-btn {
    padding: 12px 20px;
    background-color: #c6b291;
    color: white;
    border: none;
    border-radius: 6px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
  }
  
  .submit-btn:hover {
    background-color: #b7a382;
    transform: translateY(-2px);
  }
  
  .submit-btn:active {
    transform: translateY(0);
  }
  
  .cancel-btn {
    padding: 12px 20px;
    background-color: #f5f5f5;
    color: #555;
    border: 1px solid #ddd;
    border-radius: 6px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .cancel-btn:hover {
    background-color: #e5e5e5;
  }
  
  .error-message {
    color: #d9534f;
    background-color: rgba(217, 83, 79, 0.1);
    padding: 12px;
    border-radius: 6px;
    border-left: 4px solid #d9534f;
    margin-top: 15px;
  }
  
  .success-message {
    color: #5cb85c;
    background-color: rgba(92, 184, 92, 0.1);
    padding: 12px;
    border-radius: 6px;
    border-left: 4px solid #5cb85c;
    margin-top: 15px;
  }
  
  /* Media queries */
  @media (max-width: 768px) {
    .user-profile-container {
      padding: 15px;
    }
    
    .form-actions {
      flex-direction: column-reverse;
    }
    
    .submit-btn,
    .cancel-btn {
      width: 100%;
    }
  }