/* Prestations.css - Fichier CSS unifié pour tous les composants liés aux prestations */

/* ===== STYLES COMMUNS ===== */
.tab-content-container {
  margin-top: 20px;
}

.tab-content {
  display: none;
}

.tab-content.active {
  display: block;
}

/* ===== STYLES DE LA LISTE DES PRESTATIONS ===== */
/* Filtres et recherche */
.filters-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-wrap: wrap;
  gap: 10px;
}

.filter-buttons {
  display: flex;
  gap: 10px;
}

.filter-buttons select {
  padding: 8px 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
}

.search-container {
  flex-grow: 1;
  max-width: 500px;
}

.search-container input {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 0.95rem;
}

/* Catégories */
.categories-container {
  margin-bottom: 20px;
}

.categories-container h3 {
  margin-bottom: 10px;
  font-size: 1.1rem;
  color: #555;
}

.category-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.category-btn {
  padding: 8px 16px;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.category-btn:hover {
  background-color: #e5e5e5;
}

.category-btn.active {
  background-color: #c6b291;
  color: white;
  border-color: #c6b291;
}

/* Importation */
.import-container {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.import-btn {
  padding: 10px 20px;
  background-color: #c6b291;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.import-btn:hover {
  background-color: #b7a382;
}

.file-input {
  display: none;
}

/* Grille des prestations */
.prestations-list {
  width: 100%;
}

.no-prestations {
  padding: 30px;
  text-align: center;
  background-color: #f9f9f9;
  border-radius: 8px;
  border: 1px dashed #ddd;
  color: #777;
  font-style: italic;
}

/* Grille des prestations */
.prestations-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  width: 100%;
}

/* Carte de prestation */
.prestation-card {
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s, box-shadow 0.2s;
  display: flex;
  flex-direction: column;
}

.prestation-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.prestation-header {
  padding: 15px;
  display: flex;
  align-items: center;
  gap: 15px;
  border-bottom: 1px solid #f0f0f0;
}

.prestation-image {
  width: 60px;
  height: 60px;
  border-radius: 8px;
  overflow: hidden;
  flex-shrink: 0;
}

.prestation-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.placeholder-image {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 24px;
  font-weight: bold;
}

.prestation-title {
  flex-grow: 1;
}

.prestation-title h3 {
  margin: 0 0 5px 0;
  font-size: 1.1rem;
  color: #333;
}

.category-badge {
  display: inline-block;
  padding: 3px 8px;
  background-color: #f0f0f0;
  border-radius: 4px;
  font-size: 0.8rem;
  color: #666;
}

.prestation-details {
  padding: 15px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.prestation-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.prestation-price {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.price-item {
  display: flex;
  align-items: center;
  gap: 5px;
}

.price-label {
  font-size: 0.85rem;
  color: #777;
}

.price-value {
  font-weight: 600;
  color: #333;
}

.prestation-duration {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #666;
  font-size: 0.9rem;
}

.duration-icon {
  font-size: 1.1rem;
}

.prestation-description {
  padding-top: 10px;
  border-top: 1px solid #f0f0f0;
}

.prestation-description p {
  margin: 0;
  font-size: 0.9rem;
  color: #666;
  line-height: 1.4;
}

.prestation-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding: 15px;
  background-color: #f9f9f9;
  border-top: 1px solid #f0f0f0;
}

.action-btn {
  padding: 8px 15px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
  font-size: 0.9rem;
}

.edit-btn {
  background-color: #c6b291;
  color: white;
  border: none;
}

.edit-btn:hover {
  background-color: #b7a382;
}

.delete-btn {
  background-color: white;
  color: #d9534f;
  border: 1px solid #d9534f;
}

.delete-btn:hover {
  background-color: #d9534f;
  color: white;
}

/* ===== STYLES DU FORMULAIRE DE PRESTATION ===== */
.prestation-form {
  background-color: white;
  padding: 25px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.form-layout {
  display: flex;
  gap: 30px;
  margin-bottom: 20px;
}

.form-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-row {
  display: flex;
  gap: 15px;
}

.form-group {
  margin-bottom: 5px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #555;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 0.95rem;
  transition: border-color 0.2s, box-shadow 0.2s;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  border-color: #c6b291;
  box-shadow: 0 0 0 3px rgba(198, 178, 145, 0.2);
  outline: none;
}

.price-input {
  position: relative;
}

.price-input input {
  padding-right: 30px;
}

.price-symbol {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #777;
  font-weight: 500;
}

.color-picker {
  width: 50px !important;
  height: 50px;
  padding: 2px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.color-preview {
  margin-top: 10px;
  padding: 10px 15px;
  border-radius: 6px;
  color: white;
  font-weight: 500;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.2s;
}

.image-upload-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.image-preview {
  width: 100%;
  height: 150px;
  border: 2px dashed #ddd;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
  background-color: #f9f9f9;
  transition: all 0.2s;
}

.image-preview:hover {
  border-color: #c6b291;
  background-color: #f5f5f5;
}

.no-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  color: #888;
}

.image-icon {
  font-size: 30px;
}

.preview-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-btn {
  padding: 8px 15px;
  background-color: #f5f5f5;
  color: #555;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  align-self: flex-start;
}

.image-btn:hover {
  background-color: #e5e5e5;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 25px;
  padding-top: 20px;
  border-top: 1px solid #eee;
}

.submit-btn {
  padding: 10px 20px;
  background-color: #c6b291;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-weight: 600;
}

.submit-btn:hover {
  background-color: #b7a382;
}

.cancel-btn {
  padding: 10px 20px;
  background-color: white;
  color: #666;
  border: 1px solid #ddd;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.cancel-btn:hover {
  background-color: #f5f5f5;
}

/* ===== STYLES RESPONSIFS ===== */
@media (max-width: 768px) {
  .form-layout {
    flex-direction: column;
    gap: 15px;
  }
  
  .form-row {
    flex-direction: column;
    gap: 15px;
  }
  
  /* Grille des prestations */
.prestations-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  width: 100%;
}
  
  .prestation-info {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  
  .category-buttons {
    flex-wrap: wrap;
  }
  
  .categories-container {
    width: 100%;
  }
}

/* Ajout pour afficher les prestations côte à côte */
.category-section {
  margin-bottom: 30px;
}

.category-title {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 15px;
  padding-bottom: 8px;
  border-bottom: 1px solid #eee;
}

/* Style pour le bouton de gestion des catégories */
.add-category-btn {
  padding: 10px 18px;
  background-color: #c6b291;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
}

.add-category-btn:hover {
  background-color: #b7a382;
}

.add-category-btn::before {
  content: "+";
  font-size: 16px;
  font-weight: bold;
}

/* Style pour le header des prestations */
.prestations-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}

.prestations-actions {
  display: flex;
  gap: 10px;
}

/* Ajustements pour le formulaire d'ajout de catégorie */
.category-form {
  margin-bottom: 20px;
}

.category-input-group {
  display: flex;
  gap: 10px;
}

.category-input-group input {
  flex-grow: 1;
}

.add-btn {
  padding: 8px 15px;
  background-color: #c6b291;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.add-btn:hover {
  background-color: #b7a382;
}

/* Style pour la liste des catégories */
.categories-list {
  margin-top: 20px;
}

.categories-list h3 {
  margin-bottom: 10px;
  font-size: 1.1rem;
  color: #555;
}

.category-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #f0f0f0;
}

.category-name {
  font-weight: 500;
}

.delete-category-btn {
  padding: 5px 10px;
  background-color: white;
  color: #d9534f;
  border: 1px solid #d9534f;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
}

.delete-category-btn:hover {
  background-color: #d9534f;
  color: white;
}

/* Style pour la modal */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 25px;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  position: relative;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.close-modal {
  position: absolute;
  top: 15px;
  right: 20px;
  font-size: 24px;
  cursor: pointer;
  color: #777;
}

.close-modal:hover {
  color: #333;
}

/* Responsivité */
@media (max-width: 768px) {
  .prestations-header {
    flex-direction: column;
    align-items: stretch;
    gap: 15px;
  }
  
  .search-container {
    max-width: 100%;
  }
  
  .category-input-group {
    flex-direction: column;
  }
}

/* Style pour le bouton de catégorie dans les onglets */
.tab-btn-category {
  background-color: #e9e3d8;
  border-color: #c6b291;
  color: #7d6e56;
}

.tab-btn-category:hover {
  background-color: #d9d0be;
}

.tab-btn-category::before {
  content: "+";
  margin-right: 5px;
  font-weight: bold;
}