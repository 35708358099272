/* App.css */
/* Reset de base */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  background-color: #f5f5f5;
  color: #333;
}

/* Styles d'en-têtes cohérents */
section h1 {
  font-size: 2rem;
  margin-block: 0.67em;
  font-weight: bold;
}

/* Styles spécifiques pour les h1 dans chaque section */
h1 {
  color: #c6b291;
  margin-bottom: 1rem;
}

/* Barre de navigation */
.navbar {
  background-color: #c6b291;
  padding: 1rem 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.nav-buttons {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.nav-btn {
  background-color: #ffffff;
  color: #c6b291;
  border: none;
  border-radius: 4px;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.nav-btn:hover {
  background-color: #e0e7f1;
  transform: translateY(-2px);
}

.nav-btn.active {
  background-color: #e0e7f1;
  box-shadow: 0 0 0 2px #eb8989;
}

/* Styles pour le menu déroulant utilisateur */
.user-dropdown {
  position: relative;
  display: inline-block;
}

.user-dropdown-btn {
  display: flex;
  align-items: center;
  gap: 5px;
  background: none;
  border: none;
  color: white;
  font-size: 0.95rem;
  cursor: pointer;
  padding: 6px 10px;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.user-dropdown-btn:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.dropdown-arrow {
  font-size: 0.7rem;
  margin-left: 5px;
}

.user-dropdown-content {
  display: block; /* Toujours affiché lorsque présent dans le DOM */
  position: absolute;
  right: 0;
  top: 100%;
  background-color: white;
  min-width: 180px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  z-index: 1;
  margin-top: 10px;
  overflow: hidden;
}


.dropdown-item {
  display: block;
  width: 100%;
  padding: 12px 15px;
  text-align: left;
  background: none;
  border: none;
  font-size: 0.95rem;
  cursor: pointer;
  transition: background-color 0.2s;
  color: #333;
}

.dropdown-item:hover {
  background-color: #f5f5f5;
}

.profile-btn {
  color: #333;
  border-bottom: 1px solid #eee;
}

.profile-btn:hover {
  color: #c6b291;
}

.dropdown-item.logout-btn {
  color: #d9534f;
}

.dropdown-item.logout-btn:hover {
  background-color: #fee;
}

/* Contenu principal */
.main-content {
  margin-top: 80px;
  padding: 1rem;
  width: 100%;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  min-height: calc(100vh - 100px);
  box-sizing: border-box;
}

.content-section {
  background-color: white;
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
}

p {
  line-height: 1.6;
}

/* Page d'accès non autorisé */
.unauthorized-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f9f9f9;
}

.unauthorized-content {
  background-color: white;
  border-radius: 8px;
  padding: 30px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 500px;
  width: 90%;
}

.unauthorized-content h1 {
  color: #dc3545;
  margin-bottom: 20px;
}

.unauthorized-content p {
  margin-bottom: 25px;
  color: #555;
}

.back-link {
  display: inline-block;
  padding: 10px 20px;
  background-color: #c6b291;
  color: white;
  text-decoration: none;
  border-radius: 6px;
  font-weight: 600;
  transition: background-color 0.3s;
}

.back-link:hover {
  background-color: #b7a382;
}

/* Styles pour les onglets */
.tabs-container {
  display: flex;
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
}

.tab-btn {
  padding: 10px 20px;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 1rem;
  border-bottom: 3px solid transparent;
  transition: all 0.3s ease;
}

.tab-btn.active {
  border-bottom: 3px solid #c6b291;
  color: #c6b291;
  font-weight: 600;
}

.tab-content {
  display: none;
}

.tab-content.active {
  display: block;
}

/* Styles pour les formulaires */
.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
}

input,
textarea,
select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

textarea {
  resize: vertical;
}

.full-width {
  width: 100%;
}

.form-actions {
  margin-top: 20px;
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.submit-btn {
  padding: 10px 20px;
  background-color: #c6b291;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-btn:hover {
  background-color: #b7a382;
}

.reset-btn,
.cancel-btn {
  padding: 10px 20px;
  background-color: #f5f5f5;
  color: #333;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.reset-btn:hover,
.cancel-btn:hover {
  background-color: #e5e5e5;
}

/* Styles pour les tableaux */
table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

th, td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #f2f2f2;
  font-weight: 600;
}

tr:hover {
  background-color: #f9f9f9;
}

/* Styles pour les modals */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 600px;
  position: relative;
  max-height: 90vh;
  overflow-y: auto;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 28px;
  color: #aaa;
  cursor: pointer;
  transition: color 0.3s;
}

.close-modal:hover {
  color: #333;
}

/* Styles pour le modal de profil */
.profile-modal .modal-content {
  padding: 0;
  overflow: hidden;
  width: 90%;
  max-width: 700px;
}

.profile-modal .close-modal {
  z-index: 10;
  color: #555;
  background-color: rgba(255, 255, 255, 0.8);
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.profile-modal .close-modal:hover {
  background-color: rgba(255, 255, 255, 0.9);
  color: #333;
}

/* Styles pour les boutons d'action */
.action-btn {
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.edit-btn {
  background-color: #c6b291;
  color: white;
  margin-right: 5px;
}

.edit-btn:hover {
  background-color: #b7a382;
}

.delete-btn {
  background-color: #d9534f;
  color: white;
}

.delete-btn:hover {
  background-color: #c9302c;
}

/* Styles pour la recherche */
.search-container {
  margin-bottom: 20px;
}

.search-container input {
  padding: 10px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

/* Styles responsifs */
@media (max-width: 768px) {
  .nav-container {
    flex-direction: column;
    gap: 15px;
  }
  
  .nav-buttons {
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }
  
  .user-info {
    width: 100%;
    justify-content: space-between;
    margin-left: 0;
  }
  
  .content-section {
    padding: 1rem;
  }
  
  .modal-content {
    width: 95%;
  }
  
  .form-actions {
    flex-direction: column-reverse;
  }
  
  .submit-btn, 
  .cancel-btn, 
  .reset-btn {
    width: 100%;
  }
}

/* Ajoutez ces styles à votre fichier App.css */

.user-info {
  display: flex;
  align-items: center;
  gap: 15px;
}

.company-name-display {
  margin-right: 15px;
  padding-right: 15px;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
}

.company-name {
  font-weight: 600;
  color: #faf0e6; /* Couleur légèrement différente pour mettre en évidence */
  font-size: 0.9rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

@media (max-width: 768px) {
  .user-info {
    flex-direction: column;
    align-items: flex-end;
    gap: 5px;
  }
  
  .company-name-display {
    margin-right: 0;
    padding-right: 0;
    border-right: none;
    margin-bottom: 5px;
  }
  
  .company-name {
    max-width: 150px;
  }
}