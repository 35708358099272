/* Variables globales (reprises du fichier Agenda.css) */
:root {
  --primary-color: #c6b291;
  --primary-hover: #b7a382;
  --secondary-color: #e0e7f1;
  --accent-color: #eb8989;
  --text-color: #333;
  --light-gray: #f5f5f5;
  --border-color: #ddd;
  --success-color: #5cb85c;
  --warning-color: #f0ad4e;
  --info-color: #5bc0de;
  --danger-color: #d9534f;
}

/* ======================================
   CAISSE.CSS - STYLES POUR LA CAISSE
   ====================================== */

/* ==== RESET & BASE STYLES ==== */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* ==== LAYOUT PRINCIPAL ==== */
.content-section.active {
  padding: 15px;
}

/* En-tête de la caisse et actions */
.caisse-header-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-bottom: 20px;
}

.caisse-settings-btn {
  background-color: white;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  padding: 8px 12px;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 0.9rem;
}

.caisse-settings-btn:hover {
  background-color: var(--light-gray);
}

.caisse-settings-btn i {
  font-size: 1.1rem;
}

.refund-toggle-btn {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
  padding: 8px 15px;
  cursor: pointer;
  transition: all 0.2s;
  font-weight: 500;
}

.refund-toggle-btn:hover {
  background-color: #f5c6cb;
}

.refund-toggle-btn.active {
  background-color: #721c24;
  color: white;
}

/* ==== DISPOSITION EN GRILLE ==== */
.caisse-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  margin-bottom: 20px;
  width: 100%;
}

.caisse-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s, box-shadow 0.2s;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 220px;
  cursor: pointer;
  position: relative;
  animation: fadeInUp 0.4s ease-out forwards;
}

.caisse-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.caisse-card:active {
  transform: scale(0.98);
}

.caisse-card-header {
  background-color: var(--primary-color);
  color: white;
  padding: 15px;
  font-size: 1.2rem;
  font-weight: 600;
}

.caisse-card-body {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.caisse-card-icon {
  font-size: 48px;
  margin-bottom: 15px;
  color: var(--primary-color);
}

.caisse-card-description {
  color: var(--text-color);
  font-size: 0.95rem;
  line-height: 1.4;
}

.caisse-client-info {
  font-weight: 500;
  margin-top: 5px;
  text-align: center;
  word-break: break-word;
}

.caisse-card-badge {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: var(--success-color);
  color: white;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  animation: pulse 1.5s infinite;
}

/* ==== PANIER ==== */
.panier-container {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
  animation: fadeInUp 0.5s ease-out forwards;
}

.panier-header {
  background-color: var(--primary-color);
  color: white;
  padding: 15px;
  font-size: 1.2rem;
  font-weight: 600;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.panier-header-actions {
  display: flex;
  gap: 10px;
}

.clear-panier-btn {
  background-color: rgba(255, 255, 255, 0.3);
  border: none;
  color: white;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.2s;
}

.clear-panier-btn:hover {
  background-color: rgba(255, 255, 255, 0.4);
}

.empty-panier {
  padding: 30px;
  text-align: center;
  color: #777;
  font-style: italic;
  background-color: var(--light-gray);
}

/* Styles de la table du panier */
.panier-table {
  width: 100%;
  border-collapse: collapse;
}

.panier-table th {
  background-color: var(--light-gray);
  font-weight: 600;
  text-align: left;
  padding: 12px 15px;
  border-bottom: 1px solid var(--border-color);
}

.panier-table td {
  padding: 12px 15px;
  border-bottom: 1px solid var(--border-color);
  vertical-align: middle;
}

.panier-table tr:hover {
  background-color: rgba(198, 178, 145, 0.05);
}

.panier-table tfoot tr {
  background-color: var(--light-gray);
}

.panier-table tfoot .total-row {
  font-weight: 600;
  font-size: 1.1rem;
  background-color: #e9ecef;
}

/* Contrôle de quantité */
.quantity-control {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.quantity-btn {
  width: 28px;
  height: 28px;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  background-color: var(--light-gray);
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s;
}

.quantity-btn:hover {
  background-color: #e9e9e9;
}

.quantity-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.quantity-value {
  min-width: 30px;
  text-align: center;
  font-weight: 600;
}

/* Bouton pour supprimer du panier */
.remove-item {
  padding: 5px 10px;
  background-color: var(--danger-color);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.remove-item:hover {
  background-color: #c82333;
}

/* Styles pour le sélecteur de remise */
.discount-selector {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.discount-type-tabs {
  display: flex;
  margin-bottom: 10px;
}

.discount-tab {
  padding: 8px 15px;
  border: 1px solid var(--border-color);
  cursor: pointer;
  transition: all 0.2s;
  text-align: center;
}

.discount-tab:first-child {
  border-radius: 4px 0 0 4px;
}

.discount-tab:last-child {
  border-radius: 0 4px 4px 0;
}

.discount-tab.active {
  background-color: var(--primary-color);
  color: white;
  border-color: var(--primary-color);
}

.standard-discount,
.free-item-discount {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.discount-selector input {
  width: 80px;
  padding: 6px 10px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
}

.discount-selector select {
  padding: 6px 10px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background-color: white;
}

.free-item-select {
  width: 100%;
  padding: 8px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
}

/* ==== SECTION PAIEMENT ==== */
.caisse-section {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
}

.caisse-section h3 {
  color: var(--primary-color);
  margin-top: 0;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e0e0e0;
  font-size: 1.3rem;
}

.payment-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.payment-methods {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 15px;
  margin-bottom: 0;
}

.payment-method {
  background-color: #fff;
  padding: 12px;
  border-radius: 6px;
  border: 1px solid #e0e0e0;
  transition: all 0.2s ease;
}

.payment-method:hover {
  background-color: var(--light-gray);
  transform: translateY(-2px);
}

.payment-method input[type="radio"] {
  margin-right: 10px;
  transform: scale(1.2);
  cursor: pointer;
  width: 18px;
  height: 18px;
}

/* Bouton valider paiement */
.validate-btn {
  margin-top: 20px;
  padding: 15px 30px;
  background-color: var(--success-color);
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
  max-width: 300px;
  align-self: center;
}

.validate-btn:hover:not(:disabled) {
  background-color: #218838;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.validate-btn:disabled {
  background-color: #6c757d;
  opacity: 0.65;
  cursor: not-allowed;
}

/* ==== MODE REMBOURSEMENT ==== */
.transaction-list-container {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.transaction-list-container h3 {
  color: #721c24;
  margin-top: 0;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #f5c6cb;
}

.transactions-table {
  width: 100%;
  border-collapse: collapse;
}

.transactions-table th {
  background-color: #f8d7da;
  color: #721c24;
  text-align: left;
  padding: 12px 15px;
  border-bottom: 1px solid #f5c6cb;
}

.transactions-table td {
  padding: 12px 15px;
  border-bottom: 1px solid #f8d7da;
}

.select-transaction-btn {
  padding: 5px 10px;
  background-color: #721c24;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.select-transaction-btn:hover {
  background-color: #a71d2a;
}

.refund-form {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.refund-form h3 {
  color: #721c24;
  margin-top: 0;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #f5c6cb;
}

.refund-form textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
  min-height: 100px;
  resize: vertical;
}

/* ==== MODALS DE SÉLECTION ==== */
/* Modal overlay avec fond flouté */
.client-selector-overlay,
.product-selector-overlay,
.prestation-selector-overlay,
.giftcard-selector-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  animation: fadeInBlur 0.3s ease-out forwards;
}

/* Contenu du modal */
.client-selector-content,
.product-selector-content,
.prestation-selector-content {
  background-color: white;
  border-radius: 10px;
  width: 90%;
  max-width: 800px;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  animation: slideIn 0.3s ease-out forwards;
}

.giftcard-selector-content {
  background-color: white;
  border-radius: 10px;
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  animation: slideIn 0.3s ease-out forwards;
}

/* En-tête du modal */
.client-selector-header,
.product-selector-header,
.prestation-selector-header,
.giftcard-selector-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background-color: var(--primary-color);
  color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.client-selector-header h2,
.product-selector-header h2,
.prestation-selector-header h2,
.giftcard-selector-header h2 {
  margin: 0;
  font-size: 1.2rem;
}

.close-selector {
  background: none;
  border: none;
  color: white;
  font-size: 1.8rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  padding: 0;
  transition: transform 0.2s;
}

.close-selector:hover {
  transform: scale(1.1);
}

/* Corps du modal */
.client-selector-body {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
}

/* Onglets client */
.client-tabs {
  display: flex;
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 20px;
}

.client-tab {
  padding: 10px 20px;
  cursor: pointer;
  border-bottom: 3px solid transparent;
  transition: all 0.2s;
}

.client-tab.active {
  border-bottom: 3px solid var(--primary-color);
  color: var(--primary-color);
  font-weight: 600;
}

/* Section rendez-vous */
.rendez-vous-section {
  margin-top: 20px;
  background-color: var(--light-gray);
  border-radius: 8px;
  padding: 15px;
  border: 1px solid var(--border-color);
}

.rendez-vous-section h3 {
  margin-top: 0;
  color: #555;
  font-size: 1.1rem;
  margin-bottom: 15px;
  padding-bottom: 5px;
  border-bottom: 1px solid var(--border-color);
}

.rdv-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 10px;
}

.rdv-item {
  background-color: white;
  border-radius: 6px;
  padding: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.rdv-info {
  flex: 1;
}

.rdv-date {
  font-weight: 600;
  font-size: 0.9rem;
  color: var(--text-color);
}

.rdv-type {
  font-size: 0.85rem;
  color: #777;
  margin-top: 5px;
}

.add-rdv-btn {
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 0.85rem;
  white-space: nowrap;
  transition: background-color 0.2s;
}

.add-rdv-btn:hover {
  background-color: var(--primary-hover);
}

/* Champs obligatoires */
.form-group.required label::after {
  content: " *";
  color: var(--danger-color);
}

/* Boutons d'action */
.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 25px;
  padding-top: 15px;
  border-top: 1px solid var(--border-color);
}

.submit-btn {
  padding: 10px 20px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s;
  font-weight: 600;
}

.submit-btn:hover {
  background-color: var(--primary-hover);
}

.cancel-btn {
  padding: 10px 20px;
  background-color: white;
  color: #666;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.cancel-btn:hover {
  background-color: var(--light-gray);
}

/* ==== GRILLES DE PRODUITS/PRESTATIONS ==== */
.product-selector-filters,
.prestation-selector-filters {
  display: flex;
  gap: 15px;
  padding: 15px 20px;
  background-color: var(--light-gray);
  border-bottom: 1px solid #e0e0e0;
  flex-wrap: wrap;
}

.product-selector-filters .search-container,
.prestation-selector-filters .search-container {
  flex: 1;
  min-width: 200px;
}

.product-selector-filters input,
.prestation-selector-filters input {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  font-size: 0.95rem;
}

.category-filters {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.category-filters select {
  padding: 10px 12px;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  font-size: 0.95rem;
  min-width: 180px;
}

.product-selector-grid,
.prestation-selector-grid {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 15px;
  align-content: start;
}

.giftcard-selector-grid {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 15px;
  align-content: start;
  max-height: 300px;
}

.no-products,
.no-prestations,
.no-giftcards {
  grid-column: 1 / -1;
  text-align: center;
  padding: 30px;
  color: #777;
  font-style: italic;
}

/* Cards des produits/prestations */
.product-item,
.prestation-item,
.giftcard-item {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--border-color);
}

.product-item:hover,
.prestation-item:hover,
.giftcard-item:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.product-item-image,
.prestation-item-image,
.giftcard-item-image {
  height: 150px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--light-gray);
}

.product-item-image img,
.prestation-item-image img,
.giftcard-item-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.placeholder-image {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color);
  color: white;
  font-size: 3rem;
  font-weight: bold;
}

.product-item-details,
.prestation-item-details,
.giftcard-item-details {
  padding: 15px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.product-item-name,
.prestation-item-name,
.giftcard-item-name {
  margin: 0;
  font-size: 1rem;
  color: var(--text-color);
  font-weight: 600;
}

.product-item-ref {
  font-size: 0.8rem;
  color: #777;
}

.product-item-category,
.prestation-item-category,
.giftcard-item-type {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 5px;
}

.category-badge,
.subcategory-badge,
.type-badge {
  display: inline-block;
  padding: 2px 8px;
  font-size: 0.75rem;
  border-radius: 4px;
}

.category-badge {
  background-color: #f0f0f0;
  color: #666;
}

.subcategory-badge {
  background-color: var(--secondary-color);
  color: var(--info-color);
}

.type-badge {
  display: inline-block;
  padding: 3px 8px;
  background-color: var(--secondary-color);
  border-radius: 4px;
  font-size: 0.8rem;
  color: #666;
}

.product-item-price-stock,
.prestation-item-price-duration {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
  padding-top: 10px;
  border-top: 1px solid #f0f0f0;
}

.product-item-price,
.prestation-item-price,
.giftcard-item-price {
  font-weight: 600;
  color: var(--primary-color);
  font-size: 1.1rem;
}

.giftcard-item-price {
  margin-top: 10px;
  font-weight: 600;
  font-size: 1.2rem;
  color: #eb8989;
}

.product-item-stock {
  font-size: 0.85rem;
  color: #666;
}

.prestation-item-duration {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 0.85rem;
  color: #666;
}

.duration-icon {
  font-size: 1.1rem;
}

.prestation-item-description,
.giftcard-item-description {
  font-size: 0.85rem;
  color: #777;
  line-height: 1.4;
  margin-top: 5px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.giftcard-item-description {
  margin-top: 10px;
  font-size: 0.9rem;
  color: #777;
}

.giftcard-item-expiration {
  margin-top: auto;
  padding-top: 10px;
  font-size: 0.8rem;
  color: #999;
  font-style: italic;
}

/* Styles pour les onglets de carte cadeau */
.giftcard-tabs {
  display: flex;
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 15px;
}

.giftcard-tab {
  padding: 10px 20px;
  cursor: pointer;
  border-bottom: 3px solid transparent;
  transition: all 0.2s;
}

.giftcard-tab.active {
  border-bottom: 3px solid var(--primary-color);
  color: var(--primary-color);
  font-weight: 600;
}

/* Styles pour la section nominative */
.nominative-section {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #f0f0f0;
  width: 100%;
  background-color: #f7f7f7;
  padding: 15px;
  border-radius: 6px;
  margin-top: 15px;
  max-height: 450px;
  overflow-y: auto;
}

.client-search-container {
  display: flex;
  align-items: flex-end;
  gap: 10px;
  margin-bottom: 15px;
  width: 100%;
  flex-wrap: wrap;
}

.client-search {
  flex: 1;
  position: relative;
}

.client-search input {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
}

.new-client-btn {
  padding: 8px 12px;
  background-color: #f0f0f0;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
}

.new-client-btn:hover {
  background-color: #e0e0e0;
}

.selected-client {
  margin-top: 10px;
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.selected-client-info {
  display: flex;
  flex-direction: column;
}

.selected-client-name {
  font-weight: 600;
}

.selected-client-phone {
  font-size: 0.9rem;
  color: #777;
}

.remove-client-btn {
  background: none;
  border: none;
  font-size: 1.2rem;
  color: #999;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.2s;
}

.remove-client-btn:hover {
  background-color: #f0f0f0;
  color: var(--danger-color);
}

/* Formulaire de nouveau client */
.new-client-form {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 6px;
  border: 1px solid var(--border-color);
  margin-top: 10px;
  margin-bottom: 15px;
  max-height: 450px;
  overflow-y: auto;
}

.new-client-form h4 {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 1rem;
  color: #555;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 5px;
}

.form-row {
  display: flex;
  gap: 15px;
  margin-bottom: 10px;
  flex-wrap: wrap;
}

.form-row .form-group {
  flex: 1;
  min-width: 200px;
}

.create-client-btn {
  padding: 8px 15px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.create-client-btn:hover:not(:disabled) {
  background-color: var(--primary-hover);
}

.create-client-btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/* Styles pour les notifications d'ajout au panier */
.product-added-notification,
.prestation-added-notification,
.giftcard-added-notification {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: rgba(44, 167, 62, 0.9);
  color: white;
  padding: 12px 15px;
  border-radius: 6px;
  max-width: 300px;
  z-index: 2500;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  transform: translateY(-50px);
  opacity: 0;
  transition: transform 0.3s, opacity 0.3s;
  display: flex;
  align-items: center;
  gap: 10px;
}

.product-added-notification i,
.prestation-added-notification i,
.giftcard-added-notification i {
  font-size: 1.2rem;
  color: white;
}

.product-added-notification span,
.prestation-added-notification span,
.giftcard-added-notification span {
  font-weight: bold;
}

.product-added-notification.show,
.prestation-added-notification.show,
.giftcard-added-notification.show {
  transform: translateY(0);
  opacity: 1;
}

/* Styles pour les animations d'ajout au panier */
@keyframes itemAddedPulse {
  0% {
    transform: scale(1);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  50% {
    transform: scale(1.05);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
    background-color: rgba(44, 167, 62, 0.1);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
}

@keyframes stockDecrement {
  0% {
    transform: scale(1);
    color: inherit;
  }
  50% {
    transform: scale(1.2);
    color: var(--danger-color);
  }
  100% {
    transform: scale(1);
  }
}

.item-added-animation {
  animation: itemAddedPulse 1s ease-in-out;
}

.stock-change {
  animation: stockDecrement 0.8s ease-out;
}

/* Style pour stock faible */
.low-stock {
  color: var(--danger-color);
  font-weight: bold;
}

/* Styles pour l'autocomplétion des clients */
.search-container {
  position: relative;
  margin-bottom: 15px;
}

.client-suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 300px;
  overflow-y: auto;
  background-color: white;
  border: 1px solid var(--border-color);
  border-radius: 0 0 6px 6px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.client-suggestion {
  padding: 10px 15px;
  cursor: pointer;
  border-bottom: 1px solid #f0f0f0;
  transition: background-color 0.15s;
}

.client-suggestion:last-child {
  border-bottom: none;
}

.client-suggestion:hover,
.client-suggestion.focused {
  background-color: #f5f5f5;
}

.client-suggestion-name {
  font-weight: 500;
  margin-bottom: 3px;
}

.client-suggestion-phone {
  color: #777;
  font-size: 0.9rem;
}

.highlight {
  background-color: #FFEB99;
  font-weight: bold;
}

/* Style général pour le contenu scrollable */
.scrollable-content {
  overflow-y: auto;
  max-height: calc(90vh - 200px);
}

/* Styles pour s'assurer que les zones principales sont scrollables */
.giftcard-selector-body {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
}

/* Section pour les cartes cadeaux prédéfinies */
.giftcard-preset-section {
  overflow-y: auto;
  padding: 15px 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.giftcard-preset-section h3 {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 1.1rem;
  color: #555;
}

/* Styles pour le sélecteur de cartes cadeaux */
.giftcard-selector-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  animation: fadeInBlur 0.3s ease-out forwards;
}

.custom-giftcard-section {
  padding: 15px 20px;
  background-color: #f9f9f9;
  border-bottom: 1px solid #e0e0e0;
  max-height: 65vh;
  overflow-y: auto;
}

.custom-giftcard-section h3 {
  margin: 0 0 15px 0;
  font-size: 1.1rem;
  color: #555;
}

.custom-giftcard-form {
  display: flex;
  gap: 15px;
  align-items: flex-end;
  flex-wrap: wrap;
}

.custom-giftcard-form .form-group {
  flex: 1;
  min-width: 120px;
}

.create-giftcard-btn {
  padding: 10px 20px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.create-giftcard-btn:hover:not(:disabled) {
  background-color: var(--primary-hover);
}

.create-giftcard-btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/* Barre de défilement stylisée */
.custom-giftcard-section::-webkit-scrollbar,
.nominative-section::-webkit-scrollbar,
.new-client-form::-webkit-scrollbar,
.giftcard-selector-grid::-webkit-scrollbar {
  width: 8px;
}

.custom-giftcard-section::-webkit-scrollbar-track,
.nominative-section::-webkit-scrollbar-track,
.new-client-form::-webkit-scrollbar-track,
.giftcard-selector-grid::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.custom-giftcard-section::-webkit-scrollbar-thumb,
.nominative-section::-webkit-scrollbar-thumb,
.new-client-form::-webkit-scrollbar-thumb,
.giftcard-selector-grid::-webkit-scrollbar-thumb {
  background: #c6b291;
  border-radius: 4px;
}

.custom-giftcard-section::-webkit-scrollbar-thumb:hover,
.nominative-section::-webkit-scrollbar-thumb:hover,
.new-client-form::-webkit-scrollbar-thumb:hover,
.giftcard-selector-grid::-webkit-scrollbar-thumb:hover {
  background: #b7a382;
}

/* Ajustement de la hauteur pour éviter le débordement */
@media (max-height: 768px) {
  .custom-giftcard-section {
    max-height: 40vh;
  }
  
  .giftcard-selector-grid {
    max-height: 200px;
  }
  
  .new-client-form {
    max-height: 300px;
  }
}

/* ==== ANIMATIONS ==== */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes fadeInBlur {
  from {
    backdrop-filter: blur(0);
    background-color: rgba(0, 0, 0, 0);
  }
  to {
    backdrop-filter: blur(5px);
    background-color: rgba(0, 0, 0, 0.5);
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* ==== RESPONSIVE DESIGN ==== */
@media (max-width: 768px) {
  .caisse-grid {
    grid-template-columns: 1fr;
  }
  
  .panier-table {
    display: block;
    width: 100%;
    overflow-x: auto;
  }
  
  .panier-table thead {
    display: none;
  }
  
  .panier-table tr {
    display: block;
    margin-bottom: 15px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
  }
  
  .panier-table td {
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
    text-align: right;
    border-bottom: 1px solid var(--border-color);
  }
  
  .panier-table td:before {
    content: attr(data-label);
    font-weight: 600;
    float: left;
    text-align: left;
  }
  
  .panier-table tfoot tr {
    background-color: var(--light-gray);
    border: none;
  }
  
  .discount-selector {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }
  
  .payment-methods {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  
  .validate-btn {
    max-width: 100%;
  }
  
  .client-selector-content,
  .product-selector-content,
  .prestation-selector-content,
  .giftcard-selector-content {
    width: 95%;
    height: 90vh;
    max-height: 90vh;
  }
  
  .client-tabs {
    flex-direction: column;
    border-bottom: none;
    gap: 5px;
  }
  
  .client-tab {
    border-bottom: none;
    border-left: 3px solid transparent;
    padding: 10px;
  }
  
  .client-tab.active {
    border-bottom: none;
    border-left: 3px solid var(--primary-color);
    background-color: var(--light-gray);
  }
  
  .rdv-list {
    grid-template-columns: 1fr;
  }
  
  .product-selector-filters,
  .prestation-selector-filters {
    flex-direction: column;
    gap: 10px;
  }
  
  .category-filters {
    width: 100%;
  }
  
  .category-filters select {
    flex: 1;
  }
  
  .product-selector-grid,
  .prestation-selector-grid,
  .giftcard-selector-grid {
    grid-template-columns: 1fr;
  }
  
  .panier-table td:nth-of-type(1):before { content: "Article"; }
  .panier-table td:nth-of-type(2):before { content: "Prix HT"; }
  .panier-table td:nth-of-type(3):before { content: "Prix TTC"; }
  .panier-table td:nth-of-type(4):before { content: "Quantité"; }
  .panier-table td:nth-of-type(5):before { content: "Total HT"; }
  .panier-table td:nth-of-type(6):before { content: "Total TTC"; }
  .panier-table td:nth-of-type(7):before { content: "Actions"; }
  
  .discount-type-tabs {
    flex-direction: column;
    width: 100%;
  }
  
  .discount-tab {
    border-radius: 0;
  }
  
  .discount-tab:first-child {
    border-radius: 4px 4px 0 0;
  }
  
  .discount-tab:last-child {
    border-radius: 0 0 4px 4px;
  }
  
  .giftcard-tabs {
    flex-direction: row;
    flex-wrap: wrap;
  }
  
  .giftcard-tab {
    flex: 1;
    text-align: center;
    min-width: 120px;
  }
  
  .custom-giftcard-form {
    flex-direction: column;
  }
  
  .custom-giftcard-form .form-group {
    width: 100%;
  }
  
  .create-giftcard-btn {
    width: 100%;
    margin-top: 10px;
  }
}

/* Styles pour le composant d'import/export */

.import-export-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: var(--info-color);
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 15px;
  cursor: pointer;
  transition: background-color 0.2s;
  font-size: 0.9rem;
}

.import-export-btn:hover {
  background-color: #46b8da;
}

.import-export-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  animation: fadeInBlur 0.3s ease-out forwards;
}

.import-export-modal-content {
  background-color: white;
  border-radius: 10px;
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  animation: slideIn 0.3s ease-out forwards;
}

.import-export-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background-color: var(--info-color);
  color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.import-export-modal-header h2 {
  margin: 0;
  font-size: 1.2rem;
}

.close-modal {
  background: none;
  border: none;
  color: white;
  font-size: 1.8rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  padding: 0;
  transition: transform 0.2s;
}

.close-modal:hover {
  transform: scale(1.1);
}

.import-export-tabs {
  display: flex;
  background-color: #f5f5f5;
  border-bottom: 1px solid #e0e0e0;
}

.import-export-tab {
  padding: 12px 20px;
  cursor: pointer;
  transition: all 0.2s;
  border-bottom: 3px solid transparent;
}

.import-export-tab.active {
  border-bottom: 3px solid var(--info-color);
  font-weight: 600;
  color: var(--info-color);
}

.import-export-body {
  padding: 20px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.import-section,
.export-section {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 15px;
  border: 1px solid #e0e0e0;
}

.import-section h3,
.export-section h3 {
  margin-top: 0;
  margin-bottom: 15px;
  color: #333;
  font-size: 1.1rem;
  padding-bottom: 8px;
  border-bottom: 1px solid #e0e0e0;
}

.import-form,
.export-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.form-group label {
  font-weight: 500;
  color: #555;
}

.form-group select,
.form-group input[type="file"] {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 0.95rem;
  background-color: white;
}

.form-group select {
  cursor: pointer;
}

.form-group input[type="file"] {
  padding: 8px;
}

.export-btn {
  align-self: flex-start;
  padding: 10px 20px;
  background-color: var(--info-color);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
  font-weight: 500;
}

.export-btn:hover {
  background-color: #46b8da;
}

.status-message {
  padding: 10px 15px;
  border-radius: 4px;
  margin-top: 10px;
  font-size: 0.9rem;
}

.status-message.loading {
  background-color: #f8f9fa;
  border-left: 4px solid var(--info-color);
  color: #555;
}

.status-message.success {
  background-color: #d4edda;
  border-left: 4px solid var(--success-color);
  color: #155724;
}

.status-message.error {
  background-color: #f8d7da;
  border-left: 4px solid var(--danger-color);
  color: #721c24;
  white-space: normal;
  word-break: break-word;
}

/* Styles responsifs */
@media (max-width: 768px) {
  .import-export-body {
    padding: 15px;
  }
  
  .import-section,
  .export-section {
    padding: 12px;
  }
  
  .export-btn {
    width: 100%;
  }
  
  .form-group input[type="file"] {
    width: 100%;
  }
}