/* UserManagement.css */
.user-management-container {
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    margin-bottom: 30px;
  }
  
  .user-management-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
    gap: 15px;
  }
  
  .user-management-header h2 {
    color: #c6b291;
    margin: 0;
  }
  
  .user-management-actions {
    display: flex;
    gap: 15px;
    align-items: center;
  }
  
  .search-container {
    position: relative;
  }
  
  .search-container input {
    padding: 10px 15px;
    border: 1px solid #ddd;
    border-radius: 6px;
    min-width: 250px;
    font-size: 0.95rem;
  }
  
  .add-user-btn {
    background-color: #c6b291;
    color: white;
    border: none;
    border-radius: 6px;
    padding: 10px 15px;
    font-size: 0.95rem;
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .add-user-btn:hover {
    background-color: #b7a382;
  }
  
  /* Tableau des utilisateurs */
  .users-table-container {
    overflow-x: auto;
    margin-top: 20px;
  }
  
  .users-table {
    width: 100%;
    border-collapse: collapse;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid #eee;
  }
  
  .users-table th,
  .users-table td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #eee;
  }
  
  .users-table th {
    background-color: #f5f5f5;
    font-weight: 600;
    color: #555;
  }
  
  .users-table tr:hover {
    background-color: #f9f9f9;
  }
  
  .users-table tr:last-child td {
    border-bottom: none;
  }
  
  .no-users-message {
    text-align: center;
    padding: 20px;
    font-style: italic;
    color: #999;
  }
  
  /* Badge de rôle */
  .role-badge {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 0.8rem;
    font-weight: 500;
    text-transform: capitalize;
  }
  
  .role-badge.admin {
    background-color: rgba(220, 53, 69, 0.1);
    color: #dc3545;
  }
  
  .role-badge.personnel {
    background-color: rgba(0, 123, 255, 0.1);
    color: #007bff;
  }
  
  .current-user-row {
    background-color: rgba(198, 178, 145, 0.1);
  }
  
  .current-user-badge {
    display: inline-block;
    margin-left: 8px;
    padding: 2px 6px;
    background-color: #c6b291;
    color: white;
    font-size: 0.7rem;
    border-radius: 4px;
    vertical-align: middle;
  }
  
  /* Actions sur les utilisateurs */
  .user-actions {
    display: flex;
    gap: 8px;
  }
  
  .edit-user-btn,
  .delete-user-btn {
    padding: 6px 10px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.85rem;
    transition: all 0.2s;
  }
  
  .edit-user-btn {
    background-color: #c6b291;
    color: white;
    border: none;
  }
  
  .edit-user-btn:hover {
    background-color: #b7a382;
  }
  
  .delete-user-btn {
    background-color: white;
    color: #dc3545;
    border: 1px solid #dc3545;
  }
  
  .delete-user-btn:hover {
    background-color: #dc3545;
    color: white;
  }
  
  /* Formulaire d'ajout/modification d'utilisateur */
  .user-form-container {
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 25px;
    border: 1px solid #eee;
  }
  
  .user-form-container h3 {
    margin-top: 0;
    margin-bottom: 20px;
    color: #555;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
  }
  
  .form-row {
    display: flex;
    gap: 20px;
    margin-bottom: 10px;
  }
  
  .form-group {
    margin-bottom: 15px;
    flex: 1;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: 500;
    color: #555;
  }
  
  .form-group input,
  .form-group select {
    width: 100%;
    padding: 10px 12px;
    border: 1px solid #ddd;
    border-radius: 6px;
    font-size: 0.95rem;
    transition: border-color 0.2s, box-shadow 0.2s;
  }
  
  .form-group input:focus,
  .form-group select:focus {
    border-color: #c6b291;
    box-shadow: 0 0 0 3px rgba(198, 178, 145, 0.2);
    outline: none;
  }
  
  .error-message {
    background-color: rgba(220, 53, 69, 0.1);
    color: #dc3545;
    padding: 10px;
    border-radius: 6px;
    margin-bottom: 15px;
    border-left: 3px solid #dc3545;
  }
  
  .form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
  }
  
  .cancel-form-btn,
  .submit-form-btn {
    padding: 10px 15px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 0.95rem;
    transition: all 0.2s;
  }
  
  .cancel-form-btn {
    background-color: white;
    color: #666;
    border: 1px solid #ddd;
  }
  
  .cancel-form-btn:hover {
    background-color: #f5f5f5;
  }
  
  .submit-form-btn {
    background-color: #c6b291;
    color: white;
    border: none;
  }
  
  .submit-form-btn:hover {
    background-color: #b7a382;
  }
  
  /* Responsive */
  @media (max-width: 768px) {
    .user-management-header {
      flex-direction: column;
      align-items: flex-start;
    }
    
    .user-management-actions {
      width: 100%;
      flex-direction: column;
      align-items: stretch;
    }
    
    .search-container {
      width: 100%;
    }
    
    .search-container input {
      width: 100%;
    }
    
    .add-user-btn {
      width: 100%;
      justify-content: center;
    }
    
    .form-row {
      flex-direction: column;
      gap: 0;
    }
    
    .form-actions {
      flex-direction: column-reverse;
    }
    
    .cancel-form-btn,
    .submit-form-btn {
      width: 100%;
    }
    
    .users-table th,
    .users-table td {
      padding: 10px 8px;
    }
    
    .user-actions {
      flex-direction: column;
    }
    
    .edit-user-btn,
    .delete-user-btn {
      width: 100%;
      text-align: center;
    }
  }