/* BarcodeScanner.css */
.barcode-scanner-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 2000;
  display: flex;
  flex-direction: column;
}

.barcode-scanner-header {
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333;
}

.barcode-scanner-header h2 {
  color: white;
  margin: 0;
  font-size: 1.2rem;
}

.close-scanner {
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
}

.scanner-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.scanner-viewfinder {
  width: 100%;
  max-width: 640px;
  height: 100%;
  max-height: 480px;
  overflow: hidden;
  position: relative;
  background-color: #000;
}

.scanner-viewfinder canvas {
  max-width: 100%;
  max-height: 100%;
}

.scanner-viewfinder:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 2px solid rgba(0, 255, 0, 0.5);
  z-index: 2;
}

.scanner-viewfinder:after {
  content: '';
  position: absolute;
  width: 80%;
  height: 80%;
  top: 10%;
  left: 10%;
  border: 2px solid #c6b291;
  border-radius: 10px;
  z-index: 3;
  box-shadow: 0 0 0 4000px rgba(0, 0, 0, 0.5);
  animation: pulse 2s infinite;
}

.scanner-instructions {
  margin-top: 15px;
  color: white;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 10px;
  border-radius: 5px;
  position: absolute;
  bottom: 80px;
  width: 80%;
  max-width: 400px;
  z-index: 10;
}

.scanner-error {
  background-color: rgba(255, 0, 0, 0.2);
  padding: 20px;
  border-radius: 8px;
  color: white;
  text-align: center;
  margin: 0 20px;
}

.scanner-footer {
  padding: 15px;
  display: flex;
  justify-content: center;
  background-color: #333;
}

.cancel-scan {
  padding: 10px 20px;
  background-color: #c6b291;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
}

.cancel-scan:hover {
  background-color: #b7a382;
}

@keyframes pulse {
  0% {
    opacity: 0.5;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.02);
  }
  100% {
    opacity: 0.5;
    transform: scale(1);
  }
}

/* Style pour les petits écrans (smartphones) */
@media (max-width: 600px) {
  .scanner-viewfinder {
    height: 60vh;
  }
  
  .scanner-instructions {
    bottom: 40px;
  }
}