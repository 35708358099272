/* Styles pour le composant d'historique */

.history-container {
    width: 100%;
  }
  
  .history-header {
    margin-bottom: 20px;
  }
  
  .history-actions {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  
  .toggle-filters-btn,
  .clear-history-btn {
    padding: 8px 15px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: all 0.2s;
  }
  
  .toggle-filters-btn {
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    color: #555;
  }
  
  .toggle-filters-btn:hover {
    background-color: #e5e5e5;
  }
  
  .clear-history-btn {
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
    color: #721c24;
  }
  
  .clear-history-btn:hover {
    background-color: #f5c6cb;
  }
  
  .history-filters {
    display: flex;
    gap: 15px;
    margin-bottom: 15px;
    flex-wrap: wrap;
  }
  
  .search-container {
    flex: 1;
    min-width: 250px;
  }
  
  .filter-container {
    min-width: 200px;
  }
  
  .search-container input,
  .filter-container select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 6px;
  }
  
  .advanced-filters {
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 4px;
    margin-bottom: 15px;
    border: 1px solid #eee;
  }
  
  .filter-row {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }
  
  .filter-group {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .filter-group label {
    font-size: 0.85rem;
    color: #555;
  }
  
  .filter-group select,
  .filter-group input {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .reset-filters-btn {
    align-self: flex-end;
    padding: 8px 15px;
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.85rem;
  }
  
  /* Styles pour le composant d'historique */
  
  .history-container {
    width: 100%;
  }
  
  .history-header {
    margin-bottom: 20px;
  }
  
  .history-actions {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  
  .toggle-filters-btn,
  .clear-history-btn {
    padding: 8px 15px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: all 0.2s;
  }
  
  .toggle-filters-btn {
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    color: #555;
  }
  
  .toggle-filters-btn:hover {
    background-color: #e5e5e5;
  }
  
  .clear-history-btn {
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
    color: #721c24;
  }
  
  .clear-history-btn:hover {
    background-color: #f5c6cb;
  }
  
  .history-filters {
    display: flex;
    gap: 15px;
    margin-bottom: 15px;
    flex-wrap: wrap;
  }
  
  .search-container {
    flex: 1;
    min-width: 250px;
  }
  
  .filter-container {
    min-width: 200px;
  }
  
  .search-container input,
  .filter-container select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 6px;
  }
  
  .advanced-filters {
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 4px;
    margin-bottom: 15px;
    border: 1px solid #eee;
  }
  
  .filter-row {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }
  
  .filter-group {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .filter-group label {
    font-size: 0.85rem;
    color: #555;
  }
  
  .filter-group select,
  .filter-group input {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .reset-filters-btn {
    align-self: flex-end;
    padding: 8px 15px;
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.85rem;
  }
  
  .reset-filters-btn:hover {
    background-color: #e0e0e0;
  }
  
  .filter-summary {
    font-size: 0.85rem;
    color: #666;
    margin-top: 10px;
    font-style: italic;
  }
  
  .history-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .history-item {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
    padding: 15px;
    display: flex;
    gap: 15px;
    border-left: 4px solid #c6b291;
    transition: transform 0.2s, box-shadow 0.2s;
  }
  
  .history-item:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .history-item-icon {
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: #f5f5f5;
    border-radius: 50%;
    flex-shrink: 0;
  }
  
  .history-item-content {
    flex: 1;
  }
  
  .history-item-header {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 8px;
    flex-wrap: wrap;
  }
  
  .module-badge,
  .action-badge {
    display: inline-block;
    padding: 3px 8px;
    border-radius: 4px;
    font-size: 0.8rem;
    font-weight: 600;
  }
  
  .module-badge {
    color: white;
  }
  
  .action-badge {
    background-color: #f0f0f0;
    color: #555;
  }
  
  .history-item-date {
    font-size: 0.85rem;
    color: #777;
    margin-left: auto;
  }
  
  .history-item-description {
    font-size: 0.95rem;
    margin-bottom: 5px;
  }
  
  .history-item-details {
    font-size: 0.85rem;
    color: #666;
    padding: 8px;
    background-color: #f9f9f9;
    border-radius: 4px;
    margin-top: 5px;
    border-left: 3px solid #ddd;
  }
  
  .history-item-actions {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .view-details-btn,
  .delete-item-btn {
    background: none;
    border: none;
    font-size: 1.2rem;
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    transition: background-color 0.2s;
  }
  
  .view-details-btn:hover {
    background-color: #e0e7f1;
  }
  
  .delete-item-btn:hover {
    background-color: #f8d7da;
  }
  
  .no-history {
    padding: 30px;
    text-align: center;
    background-color: #f9f9f9;
    border-radius: 8px;
    border: 1px dashed #ddd;
    color: #777;
    font-style: italic;
  }
  
  /* Styles pour les modals */
  .confirm-modal,
  .details-modal {
    max-width: 500px;
  }
  
  .confirm-modal h3,
  .details-modal h3 {
    color: #333;
    margin-top: 0;
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
  }
  
  .modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
  }
  
  .confirm-btn {
    padding: 8px 15px;
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .confirm-btn:hover {
    background-color: #c82333;
  }
  
  .cancel-btn,
  .close-btn {
    padding: 8px 15px;
    background-color: #f8f9fa;
    border: 1px solid #ddd;
    color: #212529;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .cancel-btn:hover,
  .close-btn:hover {
    background-color: #e2e6ea;
  }
  
  .delete-btn {
    padding: 8px 15px;
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
    color: #721c24;
    border-radius: 4px;
    cursor: pointer;
    margin-right: auto;
  }
  
  .delete-btn:hover {
    background-color: #f5c6cb;
  }
  
  .item-details {
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 4px;
    margin-bottom: 20px;
  }
  
  .detail-row {
    display: flex;
    margin-bottom: 10px;
    flex-wrap: wrap;
  }
  
  .detail-row:last-child {
    margin-bottom: 0;
  }
  
  .detail-label {
    font-weight: 600;
    width: 100px;
    color: #555;
    flex-shrink: 0;
  }
  
  .detail-value {
    flex: 1;
  }
  
  .details-block {
    margin-top: 5px;
    padding: 10px;
    background-color: white;
    border-radius: 4px;
    border: 1px solid #eee;
    font-family: monospace;
    white-space: pre-wrap;
  }
  
  /* Styles des onglets pour la Synthèse */
  .synthese-tabs {
    display: flex;
    gap: 5px;
    margin-bottom: 20px;
    border-bottom: 1px solid #ddd;
  }
  
  .synthese-tab {
    padding: 10px 15px;
    cursor: pointer;
    border-bottom: 3px solid transparent;
    transition: all 0.2s;
  }
  
  .synthese-tab.active {
    border-bottom: 3px solid #c6b291;
    color: #c6b291;
    font-weight: 600;
  }
  
  /* Contenu des onglets */
  .synthese-tab-content {
    display: none;
  }
  
  .synthese-tab-content.active {
    display: block;
  }
  
  /* Styles responsifs */
  @media (max-width: 768px) {
    .history-filters {
      flex-direction: column;
      gap: 10px;
    }
    
    .search-container,
    .filter-container {
      width: 100%;
    }
    
    .filter-row {
      flex-direction: column;
      gap: 10px;
    }
    
    .reset-filters-btn {
      align-self: flex-start;
    }
    
    .history-item {
      flex-direction: column;
      padding: 12px;
    }
    
    .history-item-icon {
      align-self: flex-start;
    }
    
    .history-item-header {
      flex-direction: column;
      align-items: flex-start;
      gap: 5px;
    }
    
    .history-item-date {
      margin-left: 0;
    }
    
    .history-item-actions {
      flex-direction: row;
      justify-content: flex-end;
      margin-top: 10px;
    }
    
    .detail-row {
      flex-direction: column;
    }
    
    .detail-label {
      width: 100%;
      margin-bottom: 3px;
    }
    
    .modal-actions {
      flex-direction: column;
    }
    
    .delete-btn {
      margin-right: 0;
      order: 2;
    }
  }/* Styles pour le composant d'historique */
  
  .history-container {
    width: 100%;
  }
  
  .history-header {
    margin-bottom: 20px;
  }
  
  .history-filters {
    display: flex;
    gap: 15px;
    margin-bottom: 15px;
    flex-wrap: wrap;
  }
  
  .search-container {
    flex: 1;
    min-width: 250px;
  }
  
  .filter-container {
    min-width: 200px;
  }
  
  .search-container input,
  .filter-container select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 6px;
  }
  
  .history-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .history-item {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
    padding: 15px;
    display: flex;
    gap: 15px;
    border-left: 4px solid #c6b291;
    transition: transform 0.2s, box-shadow 0.2s;
  }
  
  .history-item:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .history-item-icon {
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: #f5f5f5;
    border-radius: 50%;
    flex-shrink: 0;
  }
  
  .history-item-content {
    flex: 1;
  }
  
  .history-item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
  }
  
  .module-badge {
    display: inline-block;
    padding: 3px 8px;
    border-radius: 4px;
    color: white;
    font-size: 0.8rem;
    font-weight: 600;
  }
  
  .history-item-date {
    font-size: 0.85rem;
    color: #777;
  }
  
  .history-item-description {
    font-size: 0.95rem;
    margin-bottom: 5px;
  }
  
  .history-item-details {
    font-size: 0.85rem;
    color: #666;
    padding: 8px;
    background-color: #f9f9f9;
    border-radius: 4px;
    margin-top: 5px;
    border-left: 3px solid #ddd;
  }
  
  .no-history {
    padding: 30px;
    text-align: center;
    background-color: #f9f9f9;
    border-radius: 8px;
    border: 1px dashed #ddd;
    color: #777;
    font-style: italic;
  }
  
  /* Styles des onglets pour la Synthèse */
  .synthese-tabs {
    display: flex;
    gap: 5px;
    margin-bottom: 20px;
    border-bottom: 1px solid #ddd;
  }
  
  .synthese-tab {
    padding: 10px 15px;
    cursor: pointer;
    border-bottom: 3px solid transparent;
    transition: all 0.2s;
  }
  
  .synthese-tab.active {
    border-bottom: 3px solid #c6b291;
    color: #c6b291;
    font-weight: 600;
  }
  
  /* Contenu des onglets */
  .synthese-tab-content {
    display: none;
  }
  
  .synthese-tab-content.active {
    display: block;
  }
  
  /* Styles responsifs */
  @media (max-width: 768px) {
    .history-filters {
      flex-direction: column;
      gap: 10px;
    }
    
    .search-container,
    .filter-container {
      width: 100%;
    }
    
    .history-item {
      flex-direction: column;
    }
    
    .history-item-icon {
      align-self: flex-start;
    }
    
    .history-item-header {
      flex-direction: column;
      align-items: flex-start;
      gap: 5px;
    }
  }