/* Clients.css - Styles pour la section clients */

/* ===== STYLES COMMUNS ===== */
.tab-content-container {
  margin-top: 20px;
}

.tab-content {
  display: none;
}

.tab-content.active {
  display: block;
}

/* ===== STYLES DE L'EN-TÊTE ===== */
.clients-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 20px;
}

.search-container {
  flex: 1;
  max-width: 500px;
}

.search-container input {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 0.95rem;
}

/* ===== STYLES POUR IMPORT/EXPORT ===== */
.import-export-container {
  display: flex;
  gap: 10px;
}

.import-export-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #5bc0de;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 15px;
  cursor: pointer;
  transition: background-color 0.2s;
  font-size: 0.9rem;
}

.import-export-btn:hover {
  background-color: #46b8da;
}

/* Modal d'import/export */
.import-export-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  animation: fadeInBlur 0.3s ease-out forwards;
}

.import-export-modal-content {
  background-color: white;
  border-radius: 10px;
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  animation: slideIn 0.3s ease-out forwards;
}

.import-export-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background-color: #5bc0de;
  color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.import-export-modal-header h2 {
  margin: 0;
  font-size: 1.2rem;
}

.close-modal {
  background: none;
  border: none;
  color: white;
  font-size: 1.8rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  padding: 0;
  transition: transform 0.2s;
}

.close-modal:hover {
  transform: scale(1.1);
}

.import-export-body {
  padding: 20px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.import-section,
.export-section {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 15px;
  border: 1px solid #e0e0e0;
}

.import-section h3,
.export-section h3 {
  margin-top: 0;
  margin-bottom: 15px;
  color: #333;
  font-size: 1.1rem;
  padding-bottom: 8px;
  border-bottom: 1px solid #e0e0e0;
}

.import-form,
.export-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.form-group label {
  font-weight: 500;
  color: #555;
}

.form-group select,
.form-group input[type="file"] {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 0.95rem;
  background-color: white;
}

.form-group select {
  cursor: pointer;
}

.form-group input[type="file"] {
  padding: 8px;
}

.export-btn {
  align-self: flex-start;
  padding: 10px 20px;
  background-color: #5bc0de;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
  font-weight: 500;
}

.export-btn:hover {
  background-color: #46b8da;
}

.export-btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.status-message {
  padding: 10px 15px;
  border-radius: 4px;
  margin-top: 10px;
  font-size: 0.9rem;
}

.status-message.loading {
  background-color: #f8f9fa;
  border-left: 4px solid #5bc0de;
  color: #555;
}

.status-message.success {
  background-color: #d4edda;
  border-left: 4px solid #5cb85c;
  color: #155724;
}

.status-message.error {
  background-color: #f8d7da;
  border-left: 4px solid #d9534f;
  color: #721c24;
  white-space: normal;
  word-break: break-word;
}

/* ===== STYLES POUR LA LISTE DES CLIENTS ===== */
.clients-table-container {
  width: 100%;
  overflow-x: auto;
}

.clients-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.clients-table th,
.clients-table td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #eee;
  vertical-align: middle;
}

.clients-table th {
  background-color: #f5f5f5;
  font-weight: 600;
  color: #555;
}

.clients-table tr:hover {
  background-color: #f9f9f9;
}

.clients-table tr:last-child td {
  border-bottom: none;
}

.no-clients-message {
  text-align: center;
  padding: 30px;
  color: #777;
  font-style: italic;
}

/* Style pour la photo miniature du client */
.client-photo-small {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  color: #999;
  font-size: 16px;
}

.client-photo-small img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Styles pour les boutons d'action */
.action-btns {
  display: flex;
  gap: 8px;
}

.action-btn {
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 0.85rem;
  cursor: pointer;
  transition: all 0.2s;
}

.edit-btn {
  background-color: #c6b291;
  color: white;
  border: none;
}

.edit-btn:hover {
  background-color: #b7a382;
}

.delete-btn {
  background-color: white;
  color: #d9534f;
  border: 1px solid #d9534f;
}

.delete-btn:hover {
  background-color: #d9534f;
  color: white;
}

/* ===== STYLES POUR LE FORMULAIRE CLIENT ===== */
.client-form {
  background-color: white;
  border-radius: 8px;
  padding: 25px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.form-columns {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.form-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* Style pour la section photo */
.photo-section {
  flex: 0 0 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.photo-upload {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.photo-preview {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 2px dashed #ddd;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;
  cursor: pointer;
  transition: all 0.2s;
}

.photo-preview:hover {
  border-color: #c6b291;
  background-color: #f5f5f5;
}

.photo-icon {
  font-size: 48px;
  color: #ccc;
}

.photo-btn {
  padding: 8px 12px;
  background-color: #f5f5f5;
  color: #555;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  width: 100%;
  text-align: center;
  transition: all 0.2s;
}

.photo-btn:hover {
  background-color: #e5e5e5;
}

/* Style pour les champs du formulaire */
.form-fields {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-group {
  margin-bottom: 5px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
  color: #555;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 0.95rem;
  transition: border-color 0.2s, box-shadow 0.2s;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  border-color: #c6b291;
  box-shadow: 0 0 0 3px rgba(198, 178, 145, 0.2);
  outline: none;
}

.form-group.full-width {
  width: 100%;
}

.form-group textarea {
  resize: vertical;
  min-height: 70px;
}

/* Section pour les réseaux sociaux */
.social-media-container {
  border: 1px solid #eee;
  border-radius: 8px;
  padding: 15px;
  margin-top: 15px;
  background-color: #fafafa;
}

.social-media-container h3 {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 1rem;
  color: #555;
  border-bottom: 1px solid #eee;
  padding-bottom: 8px;
}

.social-networks {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.social-network {
  display: flex;
  align-items: center;
  gap: 10px;
}

.social-network-icon {
  width: 24px;
  text-align: center;
  font-size: 1.2rem;
  color: #777;
}

.social-network-select {
  width: 120px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 0.9rem;
}

.social-network-input {
  flex: 1;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 0.9rem;
}

.add-social-btn {
  margin-top: 10px;
  padding: 6px 12px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: #555;
  font-size: 0.9rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  transition: all 0.2s;
}

.add-social-btn:hover {
  background-color: #e0e0e0;
}

.remove-social-btn {
  background: none;
  border: none;
  color: #d9534f;
  cursor: pointer;
  font-size: 1.1rem;
  padding: 0;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
}

.remove-social-btn:hover {
  transform: scale(1.1);
}

/* Boutons d'action */
.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 25px;
  padding-top: 15px;
  border-top: 1px solid #eee;
}

.submit-btn {
  padding: 10px 20px;
  background-color: #c6b291;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
  font-weight: 600;
}

.submit-btn:hover {
  background-color: #b7a382;
}

.reset-btn {
  padding: 10px 20px;
  background-color: white;
  color: #666;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
}

.reset-btn:hover {
  background-color: #f5f5f5;
}

/* ===== ANIMATIONS ===== */
@keyframes fadeInBlur {
  from {
    backdrop-filter: blur(0);
    background-color: rgba(0, 0, 0, 0);
  }
  to {
    backdrop-filter: blur(5px);
    background-color: rgba(0, 0, 0, 0.5);
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* ===== STYLES RESPONSIFS ===== */
@media (max-width: 992px) {
  .form-columns {
    flex-direction: column;
  }
  
  .photo-section {
    flex: none;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .social-network {
    flex-wrap: wrap;
  }
  
  .social-network-select {
    width: 100%;
    margin-bottom: 5px;
  }
}

@media (max-width: 768px) {
  .clients-header {
    flex-direction: column;
    align-items: stretch;
  }
  
  .search-container {
    max-width: none;
  }
  
  .import-export-container {
    width: 100%;
  }
  
  .import-export-btn {
    width: 100%;
    justify-content: center;
  }
  
  .clients-table th,
  .clients-table td {
    padding: 10px 8px;
  }
  
  .action-btns {
    flex-direction: column;
    gap: 5px;
  }
  
  .action-btn {
    padding: 5px 10px;
    font-size: 0.8rem;
    white-space: nowrap;
  }
  
  .form-actions {
    flex-wrap: wrap;
  }
  
  .submit-btn, 
  .reset-btn {
    width: 100%;
  }
  
  .import-export-modal-content {
    width: 95%;
    max-height: 95vh;
  }
}

@media (max-width: 480px) {
  .clients-table {
    display: block;
  }
  
  .clients-table thead {
    display: none;
  }
  
  .clients-table tbody tr {
    display: block;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 6px;
    overflow: hidden;
  }
  
  .clients-table tbody td {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #f0f0f0;
  }
  
  .clients-table tbody td:before {
    content: attr(data-label);
    font-weight: 600;
    margin-right: 10px;
  }
  
  .client-photo-small {
    margin: 0 auto;
  }
  
  .action-btns {
    flex-direction: row;
    justify-content: flex-end;
  }
}

/* Styles pour les bannières de statut et boutons de synchronisation */
.sync-status {
  padding: 8px 16px;
  margin-bottom: 10px;
  border-radius: 4px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sync-status.syncing {
  background-color: #fff3cd;
  color: #856404;
  border: 1px solid #ffeeba;
}

.sync-status.synced {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.sync-status.error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

.retry-sync-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 2px 8px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  margin-left: 10px;
}

.retry-sync-btn:hover {
  background-color: #0069d9;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 18px;
  z-index: 1000;
}

.sync-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #6c757d;
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 8px;
}

.sync-btn:hover {
  background-color: #5a6268;
}

.sync-btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.sync-btn i {
  margin-right: 5px;
}